import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import {
  callAPI,
  controllerAPI,
  getDisbursementData,
} from "../../API/FP/Actions";
import {
  contextObj,
  contextObjforapicall,
  dateFormat,
  requestStates,
} from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import { PlacedRequestDisbursementMockData } from "../../MockData/AnchorTraderMock";
import Pagenotfound from "../../Common/pagenotfound";

function RequestDisbursement({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
}) {
  const { state } = useLocation();
  let navigate = useNavigate();
  const location = useLocation();
  const requestData = location.state?.requestData;
  const token = location.state?.authToken;
  const [escrow, setEscrowDetails] = useState([]);
  const [fundsdetails, setfundsDetails] = useState([]);
  const [disbursementDetails, setDisbursementDetails] = useState([]);
  const [creditAccountDetails, setCreditAccountDetails] = useState([]);
  const [financeRequest, setfinanceRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  useEffect(() => {
    const fetchDisbursementData = async () => {
      setIsLoading(true);
      try {
        const constructObj = contextObjforapicall(authContextObj, state.fid);
        const disbursementResponse = await controllerAPI(
          "post",
          auth?.token,
          constructObj,
          "get-disbursement-details"
        );
        if (disbursementResponse?.response?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }
        if (disbursementResponse.res.status === 200) {
          const filteredData1 =
            disbursementResponse.res.data.data.disbursementList;
          const filteredData2 =
            disbursementResponse.res.data.data.escrowTransactionDetails;
          const filteredData3 =
            disbursementResponse.res.data.data.fundsTransferTransactionDetails;
          const filteredData4 =
            disbursementResponse.res.data.data.financeRequest;
          const filteredData5 =
            disbursementResponse.res.data.data.creditAccountDetailsList;
          setEscrowDetails(filteredData2);
          setfundsDetails(filteredData3);
          setDisbursementDetails(filteredData1);
          setfinanceRequest(filteredData4);
          setCreditAccountDetails(filteredData5);
        }
      } catch (err) {
        // //console.log("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDisbursementData();
  }, [requestData, auth?.token, state]);

  if (isLoading) {
    return (
      <div>
        {" "}
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      </div>
    );
  }
  // //console.log("disbursment  data from api ", disbursementDetails);
  // //console.log("dbsment escrow data from api ", escrow);
  // //console.log("dmnt funds data from api ", fundsdetails);
  const combinedArray = disbursementDetails?.map((disbitem, index) => ({
    ...disbitem,
    escrowDetails: escrow[index],
    financeRequestt: financeRequest,
    fundsDetails: fundsdetails[index],
    creditAccountDetails: creditAccountDetails[index],
  }));
  // //console.log("the combined array", combinedArray);

  //console.log("data from api retrived ", disbursementDetails);

  return (
    <>
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3
                style={{
                  marginBottom: "5px",
                  color: "#4A3AFF",
                  marginTop: "0px",
                }}
                className="section-heading"
              >
                {" "}
                Escrow details
              </h3>
            </div>
            <div className="record-details">
              {combinedArray.length == 0 && !isLoading ? (
                <>
                  <Pagenotfound />
                </>
              ) : (
                <table style={{ width: "60%", borderCollapse: "collapse" }}>
                  <tbody>
                    {combinedArray?.map((item, index) =>
                      index === 0 ? (
                        item.dbmtStatus === "REQUEST_CREATED" ? (
                          <React.Fragment key={index}>
                            <tr>
                              <th style={headerStyle}>Finance request ID</th>
                              <td style={cellStyle}>
                                {item.financeRequestt?.financeRequestRefNo}
                              </td>
                            </tr>
                            <tr>
                              <th style={headerStyle}>
                                Amount disbursed (INR)
                              </th>
                              <td style={cellStyle}>
                                {item.amountToBeDisbursed}
                              </td>
                            </tr>
                            <tr>
                              <th style={headerStyle}>Current Status</th>
                              <td style={cellStyle}>
                                {requestStates(item.dbmtStatus)}
                              </td>
                            </tr>
                          </React.Fragment>
                        ) : (
                          <React.Fragment key={index}>
                            <tr>
                              <th style={headerStyle}>Finance request ID</th>
                              <td style={cellStyle}>
                                {item.financeRequestt?.financeRequestRefNo}
                              </td>
                            </tr>
                            <tr>
                              <th style={headerStyle}>
                                Amount disbursed (INR)
                              </th>
                              <td style={cellStyle}>
                                {item.amountToBeDisbursed}
                              </td>
                            </tr>
                            <tr>
                              <th style={headerStyle}>Current Status</th>
                              <td style={cellStyle}>
                                {requestStates(item.dbmtStatus)}
                              </td>
                            </tr>
                            <tr>
                              <th style={headerStyle}>Disbursement Date</th>
                              <td style={cellStyle}>{item.dbmtDateTime}</td>
                            </tr>
                            <tr>
                              <th style={headerStyle}>
                                Destination Account number
                              </th>
                              <td style={cellStyle}>
                                {item?.creditAccountDetails?.accName}
                              </td>
                            </tr>
                            <tr>
                              <th style={headerStyle}>
                                Destination Account Name
                              </th>
                              <td style={cellStyle}>
                                {item?.creditAccountDetails?.accNumber}
                              </td>
                            </tr>
                            <tr>
                              <th style={headerStyle}>Destination IFSC code</th>
                              <td style={cellStyle}>
                                {item?.creditAccountDetails?.ifscCode}
                              </td>
                            </tr>
                            <tr>
                              <th style={headerStyle}>
                                Transaction Reference Number
                              </th>
                              <td style={cellStyle}>
                                {item?.fundsDetails?.transactionRefNo}
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      ) : null
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

const headerStyle = {
  textAlign: "left",
  padding: "4px",
  borderBottom: "1px solid #ddd",
  width: "45%",
};

const cellStyle = {
  padding: "4px",
  borderBottom: "1px solid #ddd",
  width: "60%",
};

export default RequestDisbursement;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
