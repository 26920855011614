import styled from "styled-components";
import { objecttocompleteDisbursment } from "../../Common/Constants";
import React, { useState } from "react";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { opscontroller } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";

const Step5 = ({
  step2output,
  nextStep,
  handleChange,
  formData,
  setstep5output,
}) => {
  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showtable, setshowtable] = useState(false);
  const [data, setTableData] = useState([]);
  const navigate = useNavigate();

  const completedisbursment = async (
    disbursement,
    placedOffer,
    acceptedOffer,
    disbursementDate
  ) => {
    const constructObj = objecttocompleteDisbursment(
      disbursement,
      placedOffer,
      acceptedOffer,
      disbursementDate
    );
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "complete-disbursement"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Disbursment Completed Succesfully !");
        setAlertSeverity("success");
        setstep5output(response?.res?.data?.data);
        setbtnenable(true);
        setshowtable(true);
        setTableData(response?.res?.data?.data);
      }
      if (response?.response?.status === 400) {
        // //console.log(
        //   "response?.response?.data?.error",
        //   response?.response?.data?.erro
        // );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error while transfering funds :", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error while transfering funds !");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const disbursementDate = new Date().toISOString();
  //console.log("Disbursment date is :", disbursementDate);
  return (
    <StepContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="step-content">
          <h2>STEP 5: Complete Disbursement</h2>

          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "400px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Disbursement Id</th>
                    <th>Disbursement Status</th>
                    <th>Accepted Offer Id</th>
                    <th>Accepted Offer Status </th>
                    <th>Placed Offer Id</th>
                    <th>Placed Offer Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{step2output?.disbursement?.id}</td>
                    <td>{step2output?.disbursement?.dbmtStatus}</td>
                    <td>{step2output?.disbursement?.acceptedOffer?.id}</td>
                    <td>{step2output?.disbursement?.acceptedOffer?.status}</td>
                    <td>{step2output?.placedOffer?.id}</td>
                    <td>{step2output?.placedOffer?.status}</td>
                  </tr>
                </tbody>
              </table>
              <Button
                disabled={btnenable}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                  margin: "5px",
                  opacity: !btnenable ? "1" : "0.5",
                }}
                onClick={() => {
                  completedisbursment(
                    step2output?.disbursement,
                    step2output?.placedOffer,
                    step2output?.acceptedOffer,
                    disbursementDate
                  );
                }}
              >
                Complete Disbursement
              </Button>
              {showtable === true ? (
                <>
                  <h4>
                    Disbursement Process Completed Succesfully you can find the
                    Changed Statuses of Accepted Offer ,Placed Offer Disbursment
                    Records
                  </h4>
                  <table style={{ marginTop: "10px" }}>
                    <thead>
                      <tr>
                        <th>Disbursement Id</th>
                        <th>Disbursement Status</th>
                        <th>Accepted Offer Id</th>
                        <th>Accepted Offer Status </th>
                        <th>Placed Offer Id</th>
                        <th>Placed Offer Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data?.disbursement?.id}</td>
                        <td>{data?.disbursement?.dbmtStatus}</td>
                        <td>{data?.acceptedOffer?.id}</td>
                        <td>{data?.acceptedOffer?.status}</td>
                        <td>{data?.placedOffer?.id}</td>
                        <td>{data?.placedOffer?.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : (
                <></>
              )}
            </div>
          )}

          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>

        <button
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            opacity: btnenable ? "1" : "0.5",
            padding: "15px 30px",
            fontSize: "15px",
          }}
          onClick={nextStep}
          className="button"
          disabled={!btnenable}
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default Step5;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  table {
    // width: 300%;
    margin: 10px;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  tr {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .button:hover {
    background-color: #0056b3;
  }
`;
