import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Snackbar,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ProfileKYC from "./ProfileKYC";
import Modal from "../../Common/Modal";
import UpdatesIframe from "../../Common/UpdatesIframe";
import {
  kycContextObjController,
  kycMultiGstIdController,
  multigstController,
  profileContextObjController,
} from "../../Common/Constants";
import { controllerAPI } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";

function ProfileDetails({
  profileDetailsApiData,
  presignedURl,
  isLoading,
  setIsLoading,
  firmMemberDetailsList,
  allTypesOfFirmsData,
  gstinList,
  setValue,
  setSelectedKycMasterDetails,
  setShowSingleGst,
  showSingleGst,
}) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [modalOpen, setModalOpen] = useState(false);

  const [personName, setPersonName] = useState("");
  const [personMobileNumber, setPersonMobileNumber] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personCompanyName, setPersonCompanyName] = useState("");
  const [personCompanyAddress, setPersonCompanyAddress] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [gstTableData, setGstTableData] = useState([]);
  const [gstcompanyname, setgstcompanyname] = useState("");
  const [acceptDeclaration, setAcceptDeclaration] = useState(false);
  const [acceptDeclarationcheck, setAcceptDeclarationcheck] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [anchorTraderPartnersData, setAnchorTraderPartnersData] = useState([]);
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [otpEditAccess, setOtpEditAccess] = useState("");
  const [popupbtnopeneditaccess, setpopupbtnopeneditaccess] = useState(false);
  const [showKyc, setShowKyc] = useState(false);
  const [orgaDetailsUpdateStatus, setorgaDetailsUpdateStatus] = useState();
  const [auth, setAuth] = useAuth();

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: "1",
  };

  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  const CloseModal = () => {
    setModalOpen(false);
  };

  const openpopup = () => {
    setpopupbtnopen(true);
  };
  const closepopup = () => {
    setpopupbtnopen(false);
  };

  useEffect(() => {
    const list = document.getElementsByClassName("box-mainHeader")[0];
    if (modalOpen) {
      list.style.overflow = "hidden";
    } else {
      list.style.overflow = "auto";
    }
  }, [modalOpen]);

  const saveMultiGst = async () => {
    setIsLoading(true);
    try {
      const constructObjgst = multigstController(
        authContextObj,
        anchorTraderID,
        gstcompanyname,
        gstNumber
      );
      const savegstdetails = await controllerAPI(
        "post",
        auth?.token,
        constructObjgst,
        "create-gstin"
      );

      if (savegstdetails.res.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Saved Details Successfully");
        setAlertSeverity("success");
      }
    } catch (err) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured. Please try later");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
      setpopupbtnopen(false);
      window.location.reload();
    }
  };
  const saveProfileDetails = async () => {
    let profileObj = profileDetailsApiData;
    profileObj["anchorTraderName"] = personName;
    profileObj["phoneNumber"] = personMobileNumber;
    profileObj["personalEmailId"] = personEmail;

    profileObj["organization"]["acceptTerms"] = acceptTerms;
    profileObj["organization"]["acceptDeclaration"] = acceptDeclaration;
    profileObj["organization"]["orgName"] = personCompanyName;
    profileObj["organization"]["orgAddress"] = personCompanyAddress;
    /*const profileObj = {
      id: profileDetailsApiData?.id,
      atId: parseInt(anchorTraderID),
      atUlidId: profileDetailsApiData?.atUlidId,
      orgId: profileDetailsApiData?.orgId,
      // tenantId: auth?.userDetails?.tenantId,
      tenantId: profileDetailsApiData?.tenantId,
      customerName: personName,
      orgName: personCompanyName,
      gstId: profileDetailsApiData?.gstId,
      phoneNumber: personMobileNumber,
      anchorTraderName: personName,
      location: personCompanyAddress,
      anchorTraderGST: profileDetailsApiData?.anchorTraderGST,
      anchorTraderSector: profileDetailsApiData?.anchorTraderSector,
      anchorTraderPAN: profileDetailsApiData?.anchorTraderPAN,
      kycCompleted: profileDetailsApiData?.kycCompleted,
      bankDetails: profileDetailsApiData?.bankDetails,
      emailId: profileDetailsApiData?.emailId,
      personalEmailId: personEmail,
      erpAccess: profileDetailsApiData?.erpAccess,
      typeOfFirm: profileDetailsApiData?.typeOfFirm,
      panStatus: profileDetailsApiData?.panStatus,
      tosDocument: profileDetailsApiData?.tosDocument,
      acceptTerms: acceptTerms,
      organization: profileDetailsApiData.organization,
      acceptDeclaration: acceptDeclaration,
      acceptDeclarationcheck: acceptDeclarationcheck,
      gstRegistrationCertificateUploadStatus:
        profileDetailsApiData?.gstRegistrationCertificateUploadStatus,
      gstRegistrationCertificateVerificationStatus:
        profileDetailsApiData?.gstRegistrationCertificateVerificationStatus,
      udhyamRegistrationcertificateUploadStatus:
        profileDetailsApiData?.udhyamRegistrationcertificateUploadStatus,
      udhyamRegistrationcertificateVerificationStatus:
        profileDetailsApiData?.udhyamRegistrationcertificateVerificationStatus,
      kycDeclaration: profileDetailsApiData?.kycDeclaration,
    };*/
    setIsLoading(true);
    try {
      const constructObj = kycContextObjController(
        authContextObj,
        anchorTraderID,
        profileObj,
        firmMemberDetailsList,
        allTypesOfFirmsData
      );
      const saveProfileDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "save-at-profile-details"
      );

      if (saveProfileDetailsResponse.res.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Saved Details Successfully");
        setAlertSeverity("success");
      }
    } catch (err) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured. Please try later");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const getMultiKycDetails = async (id) => {
    const Gstid = id;
    setIsLoading(true);
    setShowSingleGst(true);
    try {
      const constructObj = kycMultiGstIdController(
        authContextObj,
        Gstid,
        anchorTraderID
      );
      const multiGstKycDetails = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "get-kyc-master-record"
      );

      if (multiGstKycDetails?.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage(multiGstKycDetails?.response?.data?.message);
        setAlertSeverity("error");
      }
      if (multiGstKycDetails?.res?.status === 200) {
        setSelectedKycMasterDetails(multiGstKycDetails);
        setValue("3");
      }
    } catch (err) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured. Please try later");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    saveProfileDetails();
  };
  const handlemultigstchange = (e) => {
    const inputval = e.target.value;
    setGstNumber(inputval);
  };
  const handleOtpForVerification = (e) => {
    const inputt = e.target.value;
    setOtpEditAccess(inputt);
  };

  const handlemulticmpnamechange = (e) => {
    const inputv = e.target.value;
    setgstcompanyname(inputv);
  };

  useEffect(() => {
    setPersonName(profileDetailsApiData?.anchorTraderName);
    setPersonMobileNumber(profileDetailsApiData?.phoneNumber);
    setPersonEmail(profileDetailsApiData?.personalEmailId);

    setPersonCompanyName(profileDetailsApiData?.organization?.orgName);
    setPersonCompanyAddress(profileDetailsApiData?.organization?.orgAddress);
    setAcceptDeclaration(
      profileDetailsApiData?.organization?.acceptDeclaration
    );
    setorgaDetailsUpdateStatus(
      profileDetailsApiData?.organization?.orgaDetailsUpdateStatus
    );

    setAcceptDeclarationcheck(
      profileDetailsApiData?.organization?.acceptDeclaration
    );
    setAcceptTerms(profileDetailsApiData?.organization?.acceptTerms);

    setAnchorTraderPartnersData(firmMemberDetailsList);
    setAcceptTerm(profileDetailsApiData?.organization?.acceptTerms);
    setGstTableData(gstinList);
  }, [profileDetailsApiData, presignedURl]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const [universalclick, setuniversalclick] = useState(false);
  const editbtnclick = () => {
    setpopupbtnopeneditaccess(true);
  };

  const cancelclick = () => {
    setuniversalclick(false);
  };

  const handleEditAccessRestriction = () => {
    setuniversalclick(true);
  };
  return (
    <div
      className="body-text"
      style={{ paddingTop: "0px", display: "flex", flexDirection: "row" }}
    >
      {showKyc === true ? (
        <ProfileKYC
          profileDetailsApiData={profileDetailsApiData}
          firmMemberDetailsList={anchorTraderPartnersData}
          allTypesOfFirmsData={allTypesOfFirmsData}
          gstinList={gstinList}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        <>
          {" "}
          {modalOpen ? (
            <Modal
              bgcolor={"#EEEEEE"}
              ordered={modalOpen}
              left={"3%"}
              modelClosed={CloseModal}
            >
              <UpdatesIframe
                closefunction={CloseModal}
                updateUrl={presignedURl}
                updateName={"updateName"}
                video={false}
              />
            </Modal>
          ) : null}
          <form
            className="form"
            onSubmit={handleSubmit}
            style={{ width: "1200px" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ color: "#000", fontWeight: "bold" }}>
                  Personal Details
                </div>
                <div
                  style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      Width: "250px",
                      margin: "5px",
                    }}
                  >
                    <TextField
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      id="standard-basic"
                      label="Name"
                      value={personName}
                      onChange={(e) => setPersonName(e.target.value)}
                      variant="outlined"
                      required
                      disabled={universalclick !== true}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      Width: "250px",
                      margin: "5px",
                    }}
                    className="removeArrow"
                  >
                    <TextField
                      inputProps={{ style: { fontSize: 14, maxLength: 10 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      id="standard-basic"
                      label="Phone number"
                      maxLength={10}
                      value={personMobileNumber}
                      onChange={(e) => setPersonMobileNumber(e.target.value)}
                      variant="outlined"
                      type="number"
                      required
                      disabled={universalclick !== true}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      Width: "250px",
                      margin: "5px",
                    }}
                  >
                    <TextField
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      id="standard-basic"
                      label="Email address"
                      value={personEmail}
                      onChange={(e) => setPersonEmail(e.target.value)}
                      variant="outlined"
                      required
                      disabled={universalclick !== true}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "3%",
                  flexDirection: "row",
                }}
              >
                <div style={{ color: "#000", fontWeight: "bold" }}>
                  Business Details
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "200px",
                      margin: "5px",
                    }}
                  >
                    <TextField
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      id="standard-basic"
                      label="Company name"
                      value={personCompanyName}
                      onChange={(e) => setPersonCompanyName(e.target.value)}
                      variant="outlined"
                      required
                      disabled={universalclick !== true}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "500px",
                      margin: "5px",
                    }}
                  >
                    <TextField
                      id="standard-multiline-static"
                      label="Company address"
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      multiline
                      value={personCompanyAddress}
                      onChange={(e) => setPersonCompanyAddress(e.target.value)}
                      inputProps={{ style: { fontSize: 14, width: "100%" } }}
                      variant="outlined"
                      required
                      disabled={universalclick !== true}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "Column",
                  flexWrap: "wrap",
                }}
              >
                <h4>Have multiple GST's? No problem add below </h4>
                <button
                  type="button"
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#4A3AFF",
                    color: "white",
                    border: "none",
                    padding: "8px 8px",
                    cursor: "pointer",
                    height: "fit-content",
                    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                    opacity: universalclick ? "1" : "0.5",
                    width: "100px",
                  }}
                  disabled={!universalclick}
                  onClick={openpopup}
                >
                  {" "}
                  Add GST
                </button>
                <Dialog
                  open={popupbtnopen}
                  onClose={closepopup}
                  fullWidth
                  maxWidth="sm"
                  PaperProps={{
                    style: { borderRadius: 20 },
                  }}
                >
                  <DialogTitle>Fill up the GST Details</DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={closepopup}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Divider />
                  <DialogContent>
                    {isLoading && (
                      <CircularProgress
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          zIndex: "1100",
                        }}
                      />
                    )}
                    <DialogContentText>
                      <form
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "20px",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                        onSubmit={saveMultiGst}
                      >
                        <TextField
                          inputProps={{ style: { fontSize: 14 } }}
                          InputLabelProps={{
                            sx: {
                              color: "#4A3AFF",
                              fontWeight: "bold",
                              [`&.${inputLabelClasses.shrink}`]: {
                                color: "#4A3AFF",
                              },
                            },
                          }}
                          style={{
                            width: "250px",
                            margin: "5px",
                          }}
                          id="standard-basic"
                          label="GST Number "
                          name={"GST Number"}
                          variant="outlined"
                          onChange={handlemultigstchange}
                          required
                        />

                        <TextField
                          inputProps={{ style: { fontSize: 14 } }}
                          InputLabelProps={{
                            sx: {
                              color: "#4A3AFF",
                              fontWeight: "bold",
                              [`&.${inputLabelClasses.shrink}`]: {
                                color: "#4A3AFF",
                              },
                            },
                          }}
                          style={{
                            width: "250px",
                            margin: "5px",
                          }}
                          id="standard-basic"
                          label="Company Name "
                          name={"Company Name "}
                          variant="outlined"
                          onChange={handlemulticmpnamechange}
                          required
                        />

                        <button
                          type="submit"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#4A3AFF",
                            color: "white",
                            border: "none",
                            padding: "8px 8px",
                            cursor: "pointer",
                            height: "fit-content",
                            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                            margin: "5px",
                            width: "100px",
                            position: "absolute",
                            right: 8,
                            bottom: 8,
                          }}
                          //onClick={handlemultiplegstsave}
                        >
                          Save
                        </button>
                      </form>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  margin: "10px 0px",
                  width: "100%",
                }}
              >
                {gstTableData?.length > 0 ? (
                  <>
                    <h3>GSTIN Details</h3>
                    <table
                      style={{
                        width: "100%",
                        margin: "1px",
                        borderCollapse: "collapse",
                        padding: "10px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: "5%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            S.No.
                          </th>
                          <th
                            style={{
                              width: "20%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            Company Name
                          </th>
                          <th
                            style={{
                              width: "15%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            Company GST
                          </th>
                          <th
                            style={{
                              width: "15%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            kyc Completed Status
                          </th>
                          <th
                            style={{
                              width: "15%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {gstTableData?.map((item, index) => (
                          <tr key={index} style={{ border: "1px solid black" }}>
                            <td style={{ padding: "8px" }}>{index + 1}</td>
                            <td style={{ padding: "8px" }}>
                              {item?.companyName}
                            </td>
                            <td style={{ padding: "8px" }}>{item?.gstId}</td>
                            <td style={{ padding: "8px" }}>
                              {item?.kycCompleted == null
                                ? "false"
                                : String(item.kycCompleted)}
                            </td>
                            <td style={{ padding: "8px" }}>
                              <button
                                type="button"
                                style={{
                                  borderRadius: "5px",
                                  backgroundColor: "#4A3AFF",
                                  color: "white",
                                  border: "none",
                                  padding: "5px 15px",
                                  cursor: "pointer",
                                  height: "fit-content",
                                  boxShadow:
                                    "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                  // opacity: universalclick === true ? 1 : 0.5,
                                  opacity: 0.5,
                                }}
                                onClick={() => {
                                  // setShowKyc(true);

                                  getMultiKycDetails(item.id);
                                }}
                                disabled
                              >
                                View KYC Details
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <h4 style={{ marginBottom: "0px", marginTop: "60px" }}>
                <Checkbox
                  {...label}
                  checked={acceptTerms}
                  disabled={acceptTerm || universalclick !== true}
                  onChange={() => setAcceptTerms(!acceptTerms)}
                  required
                />
                I have read and accepted the{" "}
                <span
                  style={{ color: "#4A3AFF", cursor: "pointer" }}
                  onClick={() => setModalOpen(true)}
                >
                  Terms and Conditions
                </span>
                .
              </h4>
              <h4 style={{ marginBottom: "5px", marginTop: "0px" }}>
                <Checkbox
                  {...label}
                  checked={acceptDeclaration}
                  disabled={acceptDeclarationcheck || universalclick !== true}
                  onChange={() => setAcceptDeclaration(!acceptDeclaration)}
                  required
                />
                I hereby declare that the information given above is true to the
                best of my knowledge and belief.
              </h4>
              <div style={{ marginTop: "15px", textAlign: "center" }}>
                <button
                  type="submit"
                  style={{
                    borderRadius: "2px",
                    backgroundColor: "#4A3AFF",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                    height: "fit-content",
                    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                    opacity:
                      universalclick === true &&
                      acceptTerms === true &&
                      acceptDeclaration === true
                        ? "1"
                        : "0.5",
                  }}
                  disabled={
                    !(
                      universalclick === true &&
                      acceptTerms === true &&
                      acceptDeclaration === true
                    )
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </form>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{
                borderRadius: "5px",
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "8px 8px",
                cursor: "pointer",
                height: "fit-content",
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                opacity:
                  orgaDetailsUpdateStatus === true || universalclick
                    ? "0.5"
                    : "1",
                width: "90px",
                marginRight: universalclick ? "10px" : "0",
              }}
              onClick={handleEditAccessRestriction}
              disabled={orgaDetailsUpdateStatus === true}
            >
              Edit Details
            </button>

            {universalclick && (
              <button
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  padding: "8px 8px",
                  cursor: "pointer",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                }}
                onClick={cancelclick}
              >
                Cancel
              </button>
            )}
          </div>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
}

export default ProfileDetails;
