import React, { useState } from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";

const Step1 = ({
  selectedRecordDetails,
  nextStep,
  setstep1output,
  handleChange,
  formData,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };
  const [btnenable, setbtnenable] = useState(false);
  const data = {
    ulid: "123456",
    batchAmt: "$1,000",
    batchAmtCcd: "001",
    creditDate: "2024-08-01",
    vaNumber: "VA123456",
    remitterName: "John Doe",
    remitterAccountNumber: "123456789",
    ifscCode: "ABCD01234",
  };

  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <h2>Step 1 :Repayment</h2>
          <h3> Check the below details :</h3>
          <div style={{ display: "flex", flexDirection: "row", gap: "100px" }}>
            <div>
              {" "}
              <p>
                Id:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.id}
                </span>
              </p>
              <p>
                Escrow Trnx Ulid Id:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.escrowTrnxDetailsUlidId}
                </span>
              </p>
              <p>
                InstaAlertId:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.instaAlertId || "-"}
                </span>
              </p>
              <p>
                InstaAlertUlidId:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.instaAlertUlidId || "-"}
                </span>
              </p>
              <p>
                Customer Code:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.customerCode}
                </span>
              </p>
              <p>
                Customer Name:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.customerName}
                </span>
              </p>
              <p>
                Product Code:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.productCode}
                </span>
              </p>
              <p>
                Transaction Type:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.transactionType}
                </span>
              </p>
              <p>
                Batch Amt:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.batchAmt}
                </span>
              </p>
            </div>
            <div>
              <p>
                Batch Amt Ccd:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.batchAmtCcd}
                </span>
              </p>
              <p>
                Credit Date:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.creditDate}
                </span>
              </p>
              <p>
                Va Number:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.vaNumber}
                </span>
              </p>
              <p>
                Utr No:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.utrNo}
                </span>
              </p>
              <p>
                Credit Generation Time:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.creditGenerationTime}
                </span>
              </p>
              <p>
                Remitter Name:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.remitterName}
                </span>
              </p>
              <p>
                Remitter Account Number:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.remitterAccountNumber}
                </span>
              </p>
              <p>
                Ifsc Code:{" "}
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {selectedRecordDetails?.ifscCode}
                </span>
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "400px",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        </div>

        <button
          onClick={nextStep}
          className="button"
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            padding: "15px 30px",
            fontSize: "15px",
          }}
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default Step1;

const StepContainer = styled.div`
  .step-content {
    max-width: 800px;
    margin: 0px 100px;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
