import styled from "styled-components";
import { objectforOPSPrepareDisb } from "../../Common/Constants";
import React, { useState } from "react";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { opscontroller } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { fontWeight } from "@mui/system";
import { useNavigate } from "react-router-dom";

const Step2 = ({
  step1output,
  nextStep,
  handleChange,
  formData,
  setstep2output,
}) => {
  const navigate = useNavigate();
  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showdata, setshowdata] = useState(false);
  const [data, setdata] = useState([]);

  const PrepareDisb = async (id) => {
    const constructObj = objectforOPSPrepareDisb(id);
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "prepare-disbursement"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Disbursment payload is created Successfully !");
        setAlertSeverity("success");
        setstep2output(response?.res?.data?.data);
        setdata(response?.res?.data?.data);
        setbtnenable(true);
        setshowdata(true);
      }
      if (response?.response?.status === 400) {
        // //console.log(
        //   "response?.response?.data?.error",
        //   response?.response?.data?.erro
        // );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Preparing  Payload !");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <h2>STEP 2: Prepare Disbursment</h2>
          <p>
            The Finance Request Id is:
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {step1output?.financeRequest?.id}
            </span>
          </p>
          <p>
            The Virtual Account Number Created for this Disbursement :
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {step1output?.escrowTransactionDetails?.vaNumber}{" "}
            </span>
          </p>

          <p>
            Amount To Be Disbursed :{" "}
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {step1output?.disbursement?.amountToBeDisbursed}
            </span>
          </p>

          <p>
            Utr No. :{" "}
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {step1output?.escrowTransactionDetails?.utrNo}
            </span>
          </p>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "400px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                disabled={btnenable}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                  margin: "5px",
                  opacity: !btnenable ? "1" : "0.5",
                }}
                onClick={() => {
                  PrepareDisb(step1output?.financeRequest?.id);
                }}
              >
                Proceed
              </Button>
            </div>
          )}

          {showdata === true ? (
            <>
              <p>
                Bene Validation Payload Created and Stored In S3:{" "}
                <a
                  href={data?.beneValidationS3Path}
                  style={{ fontWeight: "bold" }}
                  target="_blank"
                >
                  {data?.beneValidationS3Path}
                </a>
              </p>
              <p>
                Funds Transfer Payload Created and Stored In S3:{" "}
                <a
                  href={data?.fundTransferS3Path}
                  target="_blank"
                  style={{ fontWeight: "bold" }}
                >
                  {data?.fundTransferS3Path}
                </a>
              </p>
            </>
          ) : (
            <>
              <p></p>
            </>
          )}

          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
        <button
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            opacity: btnenable ? "1" : "0.5",
            padding: "15px 30px",
            fontSize: "15px",
          }}
          onClick={nextStep}
          className="button"
          disabled={!btnenable}
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default Step2;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
