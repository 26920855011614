import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../context/auth";
import { objectforOPSForReconcileRep } from "../../Common/Constants";
import { opscontroller } from "../../API/FP/Actions";
import { useNavigate } from "react-router-dom";

const Step2 = ({
  selectedRecordDetails,
  nextStep,
  handleChange,
  formData,
  setstep2output,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };
  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showdata, setshowdata] = useState(false);
  const [data, setdata] = useState([]);
  const navigate = useNavigate();

  const getReconcile = async (id) => {
    const constructObj = objectforOPSForReconcileRep(id);
    setLoading(true);
    try {
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "reconcile-repayment"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        setdata(response.res.data.data.repayment);
        setstep2output(response.res.data.data);
        setOpenSnackbar(true);
        setApiResultMessage("Reconcile Repayment Successful");
        setAlertSeverity("success");
        setshowdata(true);
        setbtnenable(true);

        // //console.log(
        //   "the output after reconcile repayment is :",
        //   response.res.data.data
        // );
      }
      if (response.res.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in performing reconcile repayment");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <div>
            {" "}
            <h2> Step 2: Reconcile Repayment</h2>
            <h5>
              Check the details below and click on Proceed to Reconcile the
              Repayment
            </h5>
            <p>
              The Escrow Transaction Id is:
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {" "}
                {selectedRecordDetails?.id}
              </span>
            </p>
            <p>
              The Virtual Account Number Created for this Repayment is :
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {selectedRecordDetails?.vaNumber}{" "}
              </span>
            </p>
            <p>
              Remitter Name :{" "}
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {selectedRecordDetails?.remitterName}
              </span>
            </p>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "400px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  disabled={btnenable}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#4A3AFF",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                    height: "fit-content",
                    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                    margin: "5px",
                    opacity: !btnenable ? "1" : "0.5",
                  }}
                  onClick={() => {
                    getReconcile(selectedRecordDetails?.id);
                  }}
                >
                  Proceed
                </Button>
              </div>
            )}
          </div>

          {showdata === true ? (
            <>
              <h5>Updated Data after Reconcile Repayment is successful</h5>
              <table style={{ marginTop: "10px" }}>
                <thead>
                  <tr>
                    <th>Repayment ID</th>
                    <th> Repayment Status</th>
                    <th>Status Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.id}</td>
                    <td>{data.repaymentStatus}</td>
                    <td>{data.statusDescription}</td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <p></p>
            </>
          )}
          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
        <button
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            opacity: btnenable ? "1" : "0.5",
            padding: "15px 30px",
            fontSize: "15px",
          }}
          onClick={nextStep}
          className="button"
          disabled={!btnenable}
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default Step2;

const StepContainer = styled.div`
  .step-content {
    max-width: 800px;
    margin: 0px 100px;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
