import React from "react";

const Modal = (props) => {
  ////console.log(props);
  return (
    <>
      {props.ordered ? (
        <div className={"modal-backdrop"} onClick={props.modelClosed}></div>
      ) : null}
      <div
        className={`modal-base ${props.class ? props.class : ""}`}
        style={{
          transform: props.ordered ? "translateY(0)" : "translateY(-50vh)",
          opacity: props.ordered ? "1" : "0",
          //   width: props.width ? props.width : "70%",
          //   left: props.left ? props.left : "15%",
          top: props.top ? props.top : "",
          backgroundColor: props.bgcolor ? props.bgcolor : "white",
          border: "0px solid #ccc",
          boxShadow: "ppx 0px 0px balck",
          //   position: "relative",
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default Modal;
