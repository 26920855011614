import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import newLogo from "../components/images/Logo.svg";
import newLogoExpand from "../components/images/logoExpand.svg";
import Dashboard from "../components/images/Dashboard.js";
import RequestForFinance from "../components/images/requestForFinance.js";
import faq from "../components/images/faq.svg";
import onboarding from "../components/images/onboarding.js";
import Workflow from "../components/images/workflow.js";
import RequestLogImg from "../components/images/requestLog.js";
import arrowLeft from "../components/images/arrow.svg";
import logout from "../components/images/logout.svg";
import help from "../components/images/help.svg";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Home from "./Home";
import { Avatar, Menu, Tab, Typography, Collapse } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useAuth } from "../context/auth";
import Footer from "./Common/Footer";
import OpsHome from "./OpsHome";
import { TabList } from "@mui/lab";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  // marginTop: "60px",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // marginTop: "60px",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#fff",
  // boxShadow: "0px 2px 20px rgba(1, 41, 112, 0.1)",
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  // marginTop: "7% !important",
  "& .MuiDrawer-paper": {
    // opacity: 5.5,
    // width: "1"
    marginTop: "7% !important",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    "& .MuiDrawer-paper": {
      width: "0px",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Header() {
  const path = window.location.pathname;
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const theme = useTheme();
  let { role } = useParams();
  const [auth, setAuth] = useAuth();

  const setPersona = (auth) => {
    if (auth?.userDetails?.defaultPersona === "ANCHOR_TRADER") {
      return "anchorTrader";
    } else if (auth?.userDetails?.defaultPersona === "TRADE_PARTNER") {
      return "tradePartner";
    } else if (auth?.userDetails?.defaultPersona === "FINANCE_PARTNER") {
      return "financePartner";
    } else {
      return "financePartner";
    }
  };
  const [open, setOpen] = React.useState(true);
  const [userRole, setUserRole] = React.useState(setPersona(auth));
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [activeIndex, setActiveIndex] = React.useState();
  const [pageActiveIndex, setPageActiveIndex] = React.useState(30);
  const dropdownRef = React.useRef(null);

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  React.useEffect(() => {
    var urlLast = window.location.href.split("/");
    var temp = urlLast;
    if (
      temp.includes("DisbursmentWizard/Wizard") ||
      temp.includes("DisbursmentWizard/RepaymentWizard") ||
      temp.includes("DisbursmentWizard/SettlementWizard")
    ) {
      setUserRole("anchorTrader");
    }
  }, []);

  let navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
      userDisplayName: "",
      userDetails: null,
    });
    localStorage.removeItem("auth");
    navigate("/creditbazaar/");
  };
  const sidebarItems = {
    anchorTrader: [
      {
        name: "Accepted Offers",
        route: "/creditbazaar/AcceptedOffer",
        img: <Dashboard fill={"#fffffff"} />,
      },

      {
        name: " Disbursment",
        route: "/creditbazaar/DisbursmentWizard/IncomingDisbursement",
        img: <RequestForFinance fill={"#ffffff"} />,
      },
      {
        name: " Repayment",
        route: "/creditbazaar/RepaymentWizard/IncomingRepayment",
        img: <Workflow fill={"#fffff"} />,
      },
      {
        name: " Settlement",
        route: "/creditbazaar/SettlemetWizard/Incomingsettlement",
        id: "at2",
        img: <RequestForFinance fill={"#fffff"} />,
      },

      // {
      //   name: "Dashboard",
      //   route: "/creditbazaar/sellersdashboard",
      //   id: "at4",
      //   img: dashboardImg,
      // },
      // {
      //   name: "Workflow",
      //   route: "/creditbazaar/workflow",
      //   id: "at5",
      //   img: workflow,
      // },
      // {
      //   name: "F.A.Q",
      //   route: "/creditbazaar/faq",
      //   id: "at6",
      //   img: faq,
      // },
    ],
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      // id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );
  const renderMobileMenu = (
    <Menu
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {/* <MenuItem
        onClick={() => {
          navigate("/creditbazaar/anchortrader-profile");
          setMobileMoreAnchorEl(null);
        }}
      >
        Profile
      </MenuItem> */}

      <MenuItem onClick={handleLogout}>Logout</MenuItem>
      {/* <MenuItem>My account</MenuItem> */}
    </Menu>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        {/* <Toolbar> */}
        <div className="container" style={{ backgroundColor: "#ECF1FC" }}>
          <div className="item" style={{ paddingLeft: "25px" }}>
            {/* <img
              src={creditlogo}
              className="leftContent"
              style={{ width: "150px" }}
            /> */}
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
              style={{ marginLeft: open ? "15px" : "0px" }}
            >
              <img src={!open ? newLogo : newLogoExpand} />
            </ListItemIcon>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: "20px",
              }}
            >
              <Box ref={dropdownRef}>
                <Collapse
                  in={dropdownOpen}
                  timeout="auto"
                  unmountOnExit
                ></Collapse>
                <List
                  sx={{
                    width: open ? 200 : 60,
                    bgcolor: "Transparent",
                    position: "relative", // Ensure the dropdown is positioned relative to the sidebar
                  }}
                >
                  <ListItem
                    button
                    onClick={handleDropdownClick}
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#0C0C0C",
                        fontWeight: "bold",
                      }}
                    >
                      {open ? "Ops WorkFlow" : "T"}
                      {dropdownOpen ? <ExpandLess /> : <ExpandMore />}
                    </Typography>
                  </ListItem>

                  {/* Dropdown with absolute positioning */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "100%", // Positions the dropdown just below the clicked item
                      left: 0,
                      width: "100%",
                      zIndex: 1, // Ensures the dropdown appears above other content
                      backgroundColor: "background.paper",
                      boxShadow: "0px 3px 6px rgba(0,0,0,0.1)", // Adds a shadow for better visibility
                    }}
                  >
                    <Collapse in={dropdownOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {sidebarItems[`anchorTrader`]?.map((item, index) => (
                          <ListItem
                            disablePadding
                            key={item.id}
                            sx={{
                              display: "block",
                              cursor: "default",
                            }}
                            active={index === activeIndex}
                          >
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                                ":hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                              onClick={() => {
                                navigate(`${item.route}`);
                                setActiveIndex(index);
                                setPageActiveIndex(null);
                                setDropdownOpen(false);
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  borderBottom:
                                    index === activeIndex
                                      ? "2px solid grey"
                                      : "none",
                                }}
                              >
                                {open && (
                                  <ListItemText
                                    primary={item.name}
                                    sx={{
                                      opacity: open ? 1 : 0,
                                      color:
                                        index === activeIndex
                                          ? "#4A3AFF"
                                          : "#4A3AFF",
                                      borderRadius: "4px",
                                      margin: "0px 5px",
                                      ":hover": {
                                        color: "#4A3AFF",
                                      },
                                    }}
                                  />
                                )}
                              </div>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </Box>
                </List>
              </Box>
            </div>

            <div style={{ marginTop: "20px" }}>
              <Avatar
                alt="Remy Sharp"
                // className={classes.proPic}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              {/* <Typography className={classes.userText}>Welcome back</Typography> */}

              <div style={{ marginLeft: "5px" }}>
                <strong
                  // className={classes.userNameText}
                  style={{ color: "#012970" }}
                >
                  ADMIN
                </strong>
                <IconButton
                  aria-label="show more"
                  // aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                  style={{ color: "#012970" }}
                >
                  <ArrowDropDownIcon
                  // className={classes.dropDownArrow}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        {/* </Toolbar> */}
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      <Box
        component="main"
        className="box-mainHeader"
        style={{ padding: "80px 30px" }}
      >
        {/* <DrawerHeader /> */}
        <OpsHome />
        {/* <Routes /> */}
        {/* <Footer /> */}
      </Box>
    </Box>
  );
}
