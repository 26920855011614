// import proceedArrow from "../../assets/images/proceedArrow.svg";
// import { makeStyles } from "@material-ui/core/styles";
// import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
// import { Button } from "@material-ui/core";
// import { COLORS } from "../../Utils/Themes/colors";
// import { invokeAllTypesSXP } from "./actions";

const UpdatesIframe = (props) => {
  //   const classes = useStyles();
  const { closefunction, updateUrl, updateName, video } = props;
  // use Rootstate in ts files const userInfo = useSelector((state: RootStateOrAny) => state.userInfo);
  //   const userInfo = useSelector((state) => state.userInfo);
  //   //console.log("userdetailos-----", userInfo);
  return (
    <div>
      <CloseIcon
        style={{
          marginLeft: "97%",
          marginTop: "10px",
          cursor: "pointer",
          width: "25px",
          paddingBottom: "5px",
          // color: COLORS.MEDIUM_BLUE03,
        }}
        onClick={closefunction}
      />
      <div
      //   className={classes.manulBox}
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <iframe
            src={updateUrl}
            title="Trovity"
            style={{
              height: video === true ? "75vh" : "75vh",
              //   width: "95%",
              width: "75vw",
              border: "none",
            }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
      {/* <div style={{ textAlign: "center", padding: "10px 0px 30px 0px" }}>
        <Button
          className={classes.clickbutton}
          style={{ display: video === true ? "none" : "inline-flex" }}
          onClick={invokeSxp}
        >
          click here to enquire
          <img
            src={proceedArrow}
            style={{ paddingLeft: "7px" }}
            alt={"proceedArrow"}
          />
        </Button>
      </div> */}
    </div>
  );
};

export default UpdatesIframe;

// const useStyles = makeStyles({
//   manulBox: {
//     // margin: "0% auto",
//     // display: "flex",
//     // width: "100%",
//     // backgroundColor: COLORS.WHITE,
//   },
//   headerText: {
//     fontSize: "18px",
//     textAlign: " center",
//     lineHeight: "28px",
//     marginTop: "-1%",
//     paddingBottom: "10px",
//     color: COLORS.BLACK_NEW,
//   },
//   headerText2: {
//     fontSize: "22px",
//     textAlign: " center",
//     lineHeight: "28px",
//     marginTop: "-1%",
//     color: COLORS.BLACK_NEW,
//     fontWeight: "bold",
//   },

//   clickbutton: {
//     color: COLORS.BLACK_NEW,
//     borderRadius: "10px",
//     background: COLORS.WHITE,
//     fontWeight: "bold",
//     borderBottom: `3px solid ${COLORS.MEDIUM_ORANGE}`,
//     "&:hover": {
//       backgroundColor: COLORS.WHITE,
//     },
//   },
// });
