import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../../context/auth";
import {
  objectforsetSettlement,
  objecttocompleteDisbursment,
} from "../../Common/Constants";
import { opscontroller } from "../../API/FP/Actions";
import { useNavigate } from "react-router-dom";
const Step3 = ({ step2output, handleChange, formData, handleSubmit }) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showtable, setshowtable] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [data, setTableData] = useState([]);
  const navigate = useNavigate();

  const setsettlement = async (id) => {
    const constructObj = objectforsetSettlement(id);
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "set-settlement"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Settlement created Succesfully !");
        setAlertSeverity("success");
        setTableData(response?.res?.data?.data);
        setshowtable(true);
        setTableData(response?.res?.data?.data);
        setDisableBtn(true);
        window.location.reload();
      }
      if (response?.response?.status === 400) {
        // //console.log(
        //   "response?.response?.data?.error",
        //   response?.response?.data?.erro
        // );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error while setting settlement :", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error while setting settlement !");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <StepContainer>
      <div className="step-content">
        <h2>Step 3: Set Settlement</h2>
        <p>Please check the details and click below </p>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <p>
              The Repayment Id is:
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {" "}
                {step2output?.repayment?.id}
              </span>
            </p>
            <p>
              The Remitter name is :
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {step2output?.escrowTransactionDetails?.remitterName}{" "}
              </span>
            </p>
            <p>
              Repayment Status :{" "}
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {step2output?.repayment?.repaymentStatus}
              </span>
            </p>
            <button
              className="button"
              onClick={() => setsettlement(step2output?.repayment?.id)}
              disabled={disableBtn === true}
              style={{ opacity: disableBtn === true ? "0.5" : "1" }}
            >
              Set Settlement
            </button>
          </>
        )}
        <Snackbar
          open={openSnackbar}
          //autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {apiResultMessage}
          </Alert>
        </Snackbar>
      </div>
    </StepContainer>
  );
};

export default Step3;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
