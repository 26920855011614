import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { opscontroller, setdisbursmentOPS } from "../API/FP/Actions";
import Pagenotfound from "../Common/pagenotfound";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { objectforOPSsetdisb } from "../Common/Constants";
import styled from "styled-components";

const AcceptedOffers = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [AnchorTraderID, setAnchorTraderID] = useState();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const openpopup = (id) => {
    setpopupbtnopen(true);
    setAnchorTraderID(id);
  };

  const closepopup = () => {
    setpopupbtnopen(false);
  };

  const popupsaved = (innoice) => {
    //console.log("we are setting disbursment for ", innoice);
    setDisbursment(innoice);
  };

  useEffect(() => {
    getAcceptedOfferDetails();
  }, [auth?.token]);

  const setDisbursment = async (innoice) => {
    setLoading(true);
    const constructObj = objectforOPSsetdisb(innoice);
    try {
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "set-disbursements"
      );
      if (response.res.status === 200) {
        //console.log("set disbursment is done!");
        setOpenSnackbar(true);
        setApiResultMessage("Set Disbursment Successful !");
        setAlertSeverity("success");
        closepopup();
      } else {
        setOpenSnackbar(true);
        setAlertSeverity("error");
        setApiResultMessage("Error setting the disbursment details");
        closepopup();
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
      setOpenSnackbar(true);
      setAlertSeverity("error");
      setApiResultMessage("Error setting the disbursment details");
      closepopup();
    } finally {
      setLoading(false);
    }
  };

  const getAcceptedOfferDetails = async () => {
    setLoading(true);
    try {
      const response = await opscontroller(
        "post",
        auth?.token,
        null,
        "get-accepted-offers"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        setTableData(response.res.data.data);
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
    } finally {
      setLoading(false);
    }
  };

  //console.log("1111 response", tableData);

  return (
    <Acceptedoff>
      <div>
        <h2> Accepted Offers</h2>

        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : tableData.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    ID
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Accepted Offer Ulid Id
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Accepted Offer Ref No
                  </th>

                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Interest Ptg
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Term
                  </th>

                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Net Amount
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Offer Date
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Offer Accepted Date
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Anchortrader
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Financepartner
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      justifycontent: "center",
                      alignitems: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.id}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.acceptedOfferUlidId}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.acceptedOfferRefNo}
                    </td>

                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.interestPtg}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.term}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.netAmount}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.status}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {" "}
                      {item.offerDate}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.offerAcceptedDate}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignItems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.anchortrader?.id}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.financepartner?.id}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        justifycontent: "center",
                        alignitems: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#4A3AFF",
                          color: "white",
                          border: "none",
                          cursor: "pointer",
                          height: "fit-content",
                          boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        }}
                        onClick={() => openpopup(item.id)}
                      >
                        Set Disbursement
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Dialog
              open={popupbtnopen}
              onClose={closepopup}
              maxWidth="md"
              PaperProps={{
                style: { borderRadius: 20 },
              }}
            >
              <DialogTitle>Set Disbursement</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={closepopup}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Divider />
              <DialogContent>
                <DialogContentText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="record-details">
                      <h3>
                        Do you want to Set Disbursement for this Accepted Offer
                        ? <br />
                        <p
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          "{AnchorTraderID}"
                        </p>
                      </h3>
                    </div>
                    <Button
                      type="button"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "8px 8px",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        margin: "5px",
                        width: "100px",
                      }}
                      onClick={() => popupsaved(AnchorTraderID)} // Pass the function reference, do not invoke it
                    >
                      Yes
                    </Button>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </>
        ) : (
          <Pagenotfound />
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {apiResultMessage}
          </Alert>
        </Snackbar>
      </div>
    </Acceptedoff>
  );
};

export default AcceptedOffers;

const Acceptedoff = styled.div`
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
`;
