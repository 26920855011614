import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  NativeSelect,
  Snackbar,
  TextField,
} from "@mui/material";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import {
  controllerAPI,
  controllerAPIForFileUpload,
} from "../../API/FP/Actions";
import {
  generateOTPContextObjController,
  gstCompanyDetailsContextObjController,
  kycContextObjController,
  kycMultiGstIdController,
  panValidateContextObjController,
  verifyOTPContextObjController,
} from "../../Common/Constants";
import VERFIED from "../../images/VERIFIED.png";

function ProfileKYC({
  profileDetailsApiData,
  firmMemberDetailsList,
  allTypesOfFirmsData,
  gstinList,
  isLoading,
  setIsLoading,
  selectedKycMasterDetails,
  setShowSingleGst,
  showSingleGst,
  showKycAccess,
}) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [kycDeclaration, setKycDeclaration] = useState(false);
  const [multiKycData, setMultiKycData] = useState([]);
  const [gstinListt, setgstinList] = useState([]);
  const [kycMasterId, setKycMasterId] = useState();
  const [typeOfFirm, setTypeOfFirm] = useState(
    profileDetailsApiData?.typeOfFirm || ""
  );
  const [companyEmail, setCompanyEmail] = useState(
    profileDetailsApiData?.emailId || ""
  );
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [panNumber, setPanNumber] = useState("");
  const [businessPanNumberOTP, setBusinessPanNumberOTP] = useState(false);
  const [businessPanNumberOTPSent, setBusinessPanNumberOTPSent] = useState(0);
  const [personalpan, setpersonalpan] = useState(false);
  const [aadharpopupsubmit, setaadharpopupsubmit] = useState(false);
  const [businessPanNumberStatus, setBusinessPanNumberStatus] = useState(0); // 0 default, 1 valid, 2 pending, 3 failed
  const [isChanged, setIsChanged] = useState(false);
  const [isAADHARChanged, setIsAADHARChanged] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState([]);
  const [personAccountNumber, setPersonAccountNumber] = useState("");
  const [personIfscCode, setPersonIfscCode] = useState("");
  const [personBankName, setPersonBankName] = useState("");
  const [personBranchName, setPersonBranchName] = useState("");
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [editpopupbtnopen, seteditpopupbtnopen] = useState(false);
  const [
    gstRegistrationCertificateUploadStatus,
    setGstRegistrationCertificateUploadStatus,
  ] = useState(false);
  const [
    udyamRegistrationCertificateUploadStatus,
    setUdyamRegistrationCertificateUploadStatus,
  ] = useState(false);

  const [
    gstRegistrationCertificateVerificationStatus,
    setGstRegistrationCertificateVerificationStatus,
  ] = useState(false);
  const [
    udyamRegistrationCertificateVerificationStatus,
    setUdyamRegistrationCertificateVerificationStatus,
  ] = useState(false);

  const [
    gstRegistrationCertificateFileName,
    setGstRegistrationCertificateFileName,
  ] = useState("");
  const [
    udyamRegistrationCertificateFileName,
    setUdyamRegistrationCertificateFileName,
  ] = useState("");

  const [partnershipDeedFileName, setPartnershipDeedFileName] = useState("");
  const [
    partnershipRegistrationCertificateFileName,
    setPartnershipRegistrationCertificateFileName,
  ] = useState("");

  const [addpartner, setaddedpartner] = useState(0);
  const [panData, setpanData] = useState("");
  /* const firmTypes = {
    Partnership: "PARTNERSHIP",
    Proprietorship: "PROPRIETORSHIP",
    LLP: "LLP",
    "Limited Company": "LIMITED_COMPANY",
  };*/

  function theTypeOfFirm(type) {
    const lowerCaseType = type.toLowerCase();

    if (
      lowerCaseType.includes("limited") &&
      lowerCaseType.includes("liability") &&
      lowerCaseType.includes("partnership")
    ) {
      return "LLP";
    } else if (
      lowerCaseType.includes("limited") &&
      lowerCaseType.includes("company")
    ) {
      return "LIMITED_COMPANY";
    } else if (lowerCaseType.includes("partnership")) {
      return "PARTNERSHIP";
    } else if (lowerCaseType.includes("proprietorship")) {
      return "PROPRIETORSHIP";
    } else if (lowerCaseType.includes("llp")) {
      return "LLP";
    } else {
      return "UNKNOWN_TYPE";
    }
  }
  let value = "";
  if (typeOfFirm === "LIMITED_COMPANY") {
    value = "Limited Company";
  } else if (typeOfFirm === "PARTNERSHIP") {
    value = "Partnership";
  } else if (typeOfFirm === "PROPRIETORSHIP") {
    value = "Proprietorship";
  } else if (typeOfFirm === "LLP") {
    value = "Llp";
  }

  const [
    certificateOfIncorporationFileName,
    setCertificateOfIncorporationFileName,
  ] = useState("");
  const [llpAgreementFileName, setLlpAgreementFileName] = useState("");

  const [memorandumOfAssociationFileName, setMemorandumOfAssociationFileName] =
    useState("");
  const [articleOfAssociationFileName, setArticleOfAssociationFileName] =
    useState("");

  const [auth, setAuth] = useAuth();
  const [selectedFileGST, setSelectedFileGST] = useState(null);
  const [selectedFileUdyam, setSelectedFileUdyam] = useState(null);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const firmMemberDetailsListSKycMaster = showSingleGst
    ? selectedKycMasterDetails?.res?.data?.data?.firmMemberDetailsList || []
    : gstinListt?.data?.firmMemberDetailsList || [];
  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  const [allAadharResponses, setAllAadharResponses] = useState([]);
  const [businesspanarray, setbusinesspanarray] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [isgstError, setgstIsError] = useState(false);
  const [noOfPartners, setNoOfPartnerss] = useState([]);
  const [partnertabledetails, setpartnertabledetails] = useState(
    firmMemberDetailsListSKycMaster
  );
  const [noOfPartnerss, setNoOfPartners] = useState(
    firmMemberDetailsListSKycMaster
  );
  const [isError, setIsError] = useState([]);
  const [gstapires, setgstapires] = useState("");
  const [otpconformed, setotpverified] = useState(false);
  const [gstnumbervalid, setgstnumbervalid] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [cancelledPartnerIndex, setCancelledPartnerIndex] = useState(null);
  const [bpanchanged, setbpanchange] = useState(false);
  const [popupbtnopeneditaccess, setPopupBtnOpenEditAccess] = useState(false);
  const [companynamechange, setCompanyNamechange] = useState(false);
  const [visibleFileName, setVisibleFileName] = useState(false);
  const [visibleFileNameTwo, setVisibleFileNameTwo] = useState(false);
  const [otpEditAccess, setOtpEditAccess] = useState("");
  const [gstID, setGstID] = useState(gstinListt?.[0]?.id || "");
  const [companynameperpan, setCompanynameperpan] = useState("");
  const [dobOfBPan, setDobOfPan] = useState(null);
  const [businessPanNameAsPerPANMatch, setBusinessPanNameAsPerPANMatch] =
    useState(false);
  const [businessPANDOBMatch, setBusinessPANDOBMatch] = useState(false);
  const [gstNumber, setGstNumber] = useState(
    showSingleGst
      ? selectedKycMasterDetails?.res?.data?.data?.kycMaster?.gstin?.gstId
      : gstinListt?.[0]?.gstId
  );
  const [kycList, setKycList] = useState([]);

  const [isKycCompleted, setIsKycCompleted] = useState(false);

  const getErpAccessDetails = async (id) => {
    const Gstid = id;
    setIsLoading(true);
    try {
      const constructObj = kycMultiGstIdController(
        authContextObj,
        Gstid,
        anchorTraderID
      );
      const multiGstKycDetails = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "get-kyc-master-record"
      );

      if (multiGstKycDetails?.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage(multiGstKycDetails?.response?.data?.message);
        setAlertSeverity("error");
      }
      if (multiGstKycDetails?.res?.status === 200) {
        setMultiKycData(multiGstKycDetails);
        const rawDate =
          multiGstKycDetails?.res?.data?.data?.kycMaster?.businessPanDob;
        const parsedDate = new Date(rawDate);
        setDobOfPan(rawDate);
        setBusinessPanNumberOTP(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.businessPanStatus ||
            false
        );
        //  setDobOfPan(new Date(multiGstKycDetails?.res?.data?.data?.kycMaster?.businessPanDob));
        setKycMasterId(multiGstKycDetails?.res?.data?.data?.kycMaster?.id);
        setCompanynameperpan(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.businessPanName
        );
        // setUdyamRegistrationCertificateFileName(multiGstKycDetails?.res?.data?.data?.kycMaster?.udhyamRegCertFileName);
        //partnership file name
        setPartnershipDeedFileName(
          multiGstKycDetails?.res?.data?.data?.kycMaster
            ?.partnershipDeedFileName
        );
        setPartnershipRegistrationCertificateFileName(
          multiGstKycDetails?.res?.data?.data?.kycMaster
            ?.partnershipRegCertFileName
        );
        //propritorship file name
        setGstRegistrationCertificateFileName(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.gstRegCertFileName
        );
        setUdyamRegistrationCertificateFileName(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.udhyamRegCertFileName
        );
        //LLP
        setCertificateOfIncorporationFileName(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.certOfIncorpFileName
        );
        setLlpAgreementFileName(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.llpAggrementFileName
        );
        //Limited_company
        setMemorandumOfAssociationFileName(
          multiGstKycDetails?.res?.data?.data?.kycMaster
            ?.memorandumOfAssocFileName
        );
        setArticleOfAssociationFileName(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.articleOfAssocFileName
        );

        setKycList(multiGstKycDetails?.res?.data?.data?.kycMaster);
        setGstNumber(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.gstin?.gstId
        );
        // setIsKycCompleted(
        //   multiGstKycDetails?.res?.data?.data?.kycMaster?.gstin?.kycCompleted
        // );
        setCompanyName(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.companyName
        );
        setPersonAccountNumber(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.bankAccountNumber
        );
        setPersonIfscCode(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.bankIFSCCode
        );
        setPersonBankName(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.bankName
        );
        setPersonBranchName(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.branchName
        );
        setPanNumber(
          multiGstKycDetails?.res?.data?.data?.kycMaster?.businessPan
        );
        setpartnertabledetails(
          multiGstKycDetails?.res?.data?.data?.firmMemberDetailsList
        );
        setNoOfPartners(
          multiGstKycDetails?.res?.data?.data?.firmMemberDetailsList
        );
      }
    } catch (err) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured. Please try later");
      setAlertSeverity("error");
      console.log("this is:", err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setgstinList(gstinList);
    if (cancelledPartnerIndex !== null) {
      // Perform additional actions here

      // Reset the cancelledPartnerIndex after handling the effect
      setCancelledPartnerIndex(null);
    }

    //setGstNumber(profileDetailsApiData?.anchorTraderGST);
    /*if (selectedKycMasterDetails?.res?.data?.data?.kycMaster) {
      setGstNumber(selectedKycMasterDetails?.res?.data?.data?.kycMaster?.gstId);
      setGstID(selectedKycMasterDetails?.res?.data?.data?.kycMaster?.id);
      getErpAccessDetails(
        selectedKycMasterDetails?.res?.data?.data?.kycMaster?.id
      );
    }*/
    if (selectedKycMasterDetails?.length === 0) {
      if (gstinListt.length > 0) {
        setGstNumber(gstinListt?.[0]?.gstId);
        // setIsKycCompleted(gstinListt?.[0]?.kycCompleted);
        setGstID(gstinListt?.[0]?.id);
        getErpAccessDetails(gstinListt?.[0]?.id);
      }
    } else {
    }

    setPersonAccountNumber(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.bankAccountNumber
        : multiKycData?.res?.data?.data?.kycMaster?.bankAccountNumber
    );
    setKycMasterId(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster?.id
        : multiKycData?.res?.data?.data?.kycMaster?.id
    );
    setPersonIfscCode(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster?.bankIFSCCode
        : multiKycData?.res?.data?.data?.kycMaster?.bankIFSCCode
    );
    setCompanynameperpan(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster?.businessPanName
        : multiKycData?.res?.data?.data?.kycMaster?.businessPanName
    );
    setBusinessPanNumberOTP(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.businessPanStatus ?? false
        : multiKycData?.res?.data?.data?.kycMaster?.businessPanStatus ?? false
    );

    setPersonBankName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster?.bankName
        : multiKycData?.res?.data?.data?.kycMaster?.bankName
    );
    setCompanyName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster?.companyName
        : multiKycData?.res?.data?.data?.kycMaster?.companyName
    );
    setPanNumber(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster?.businessPan
        : multiKycData?.res?.data?.data?.kycMaster?.businessPan
    );
    setPersonBranchName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster?.branchName
        : multiKycData?.res?.data?.data?.kycMaster?.branchName
    );
    //partnership file name
    setPartnershipDeedFileName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.partnershipDeedFileName
        : multiKycData?.res?.data?.data?.kycMaster?.partnershipDeedFileName
    );
    setPartnershipRegistrationCertificateFileName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.partnershipRegCertFileName
        : multiKycData?.res?.data?.data?.kycMaster?.partnershipRegCertFileName
    );
    //propritorship file name
    setGstRegistrationCertificateFileName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.gstRegCertFileName
        : multiKycData?.res?.data?.data?.kycMaster?.gstRegCertFileName
    );
    setUdyamRegistrationCertificateFileName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.udhyamRegCertFileName
        : multiKycData?.res?.data?.data?.kycMaster?.udhyamRegCertFileName
    );
    //LLP
    setCertificateOfIncorporationFileName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.certOfIncorpFileName
        : multiKycData?.res?.data?.data?.kycMaster?.certOfIncorpFileName
    );
    setLlpAgreementFileName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.llpAggrementFileName
        : multiKycData?.res?.data?.data?.kycMaster?.llpAggrementFileName
    );
    //Limited_company
    setMemorandumOfAssociationFileName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.memorandumOfAssocFileName
        : multiKycData?.res?.data?.data?.kycMaster?.memorandumOfAssocFileName
    );
    setArticleOfAssociationFileName(
      showSingleGst
        ? selectedKycMasterDetails?.res?.data?.data?.kycMaster
            ?.articleOfAssocFileName
        : multiKycData?.res?.data?.data?.kycMaster?.articleOfAssocFileName
    );
    //setPersonMobileNumber(profileDetailsApiData?.phoneNumber);
    // setCompanyEmail(profileDetailsApiData?.emailId);
    // setTypeOfFirm(profileDetailsApiData?.typeOfFirm);
    // setPanNumber(profileDetailsApiData?.anchorTraderPAN);
    // setCompanyName(profileDetailsApiData?.orgName);
    // setNoOfPartners(firmMemberDetailsList);
    // setPersonAccountNumber(profileDetailsApiData?.accountNumber);
    // setPersonIfscCode(profileDetailsApiData?.ifscCode);
    // setPersonBankName(profileDetailsApiData?.bankName);
    // setPersonBranchName(profileDetailsApiData?.branchName);

    setKycDeclaration(profileDetailsApiData?.kycDeclaration);
    // setBusinessPanNumberOTP(profileDetailsApiData?.panStatus);
    // setgstinList(gstinList);
    setbusinesspanarray((prevState) => ({
      ...prevState,
      pan: profileDetailsApiData?.anchorTraderPAN,
    }));

    if (profileDetailsApiData?.typeOfFirm) {
      if (profileDetailsApiData?.typeOfFirm === "PROPRIETORSHIP") {
        setGstRegistrationCertificateUploadStatus(
          allTypesOfFirmsData?.propreitorshipFirm
            ?.gstRegistrationCertificateUploadStatus
        );
        setUdyamRegistrationCertificateUploadStatus(
          allTypesOfFirmsData?.propreitorshipFirm
            ?.udhyamRegistrationcertificateUploadStatus
        );
        setGstRegistrationCertificateVerificationStatus(
          allTypesOfFirmsData?.propreitorshipFirm
            ?.gstRegistrationCertificateVerificationStatus
        );
        setUdyamRegistrationCertificateVerificationStatus(
          allTypesOfFirmsData?.propreitorshipFirm
            ?.udhyamRegistrationcertificateVerificationStatus
        );
      } else if (profileDetailsApiData?.typeOfFirm === "LLP") {
        setGstRegistrationCertificateUploadStatus(
          allTypesOfFirmsData?.llpFirm?.certificateOfIncorporationUploadStatus
        );
        setUdyamRegistrationCertificateUploadStatus(
          allTypesOfFirmsData?.llpFirm?.llpAggrementUploadStatus
        );
        setGstRegistrationCertificateVerificationStatus(
          allTypesOfFirmsData?.llpFirm
            ?.certificateOfIncorporationVerificationStatus
        );
        setUdyamRegistrationCertificateVerificationStatus(
          allTypesOfFirmsData?.llpFirm?.llpAggrementVerificationStatus
        );
      } else if (profileDetailsApiData?.typeOfFirm === "PARTNERSHIP") {
        setGstRegistrationCertificateUploadStatus(
          allTypesOfFirmsData?.partnershipFirm?.partnershipDeedUploadStatus
        );
        setUdyamRegistrationCertificateUploadStatus(
          allTypesOfFirmsData?.partnershipFirm
            ?.partnershipRegistrationCertificateUploadStatus
        );
        setGstRegistrationCertificateVerificationStatus(
          allTypesOfFirmsData?.partnershipFirm
            ?.partnershipDeedVerificationStatus
        );
        setUdyamRegistrationCertificateVerificationStatus(
          allTypesOfFirmsData?.partnershipFirm
            ?.partnershipRegistrationCertificateVerificationStatus
        );
      } else if (profileDetailsApiData?.typeOfFirm === "LIMITED_COMPANY") {
        setGstRegistrationCertificateUploadStatus(
          allTypesOfFirmsData?.limitedCompanyFirm
            ?.memorandumOfAssociationUploadStatus
        );
        setUdyamRegistrationCertificateUploadStatus(
          allTypesOfFirmsData?.limitedCompanyFirm
            ?.articleOfAssociationUploadStatus
        );
        setGstRegistrationCertificateVerificationStatus(
          allTypesOfFirmsData?.limitedCompanyFirm
            ?.memorandumOfAssociationVerificationStatus
        );
        setUdyamRegistrationCertificateVerificationStatus(
          allTypesOfFirmsData?.limitedCompanyFirm
            ?.articleOfAssociationVerificationStatus
        );
      }
    }
    // setGstRegistrationCertificateUploadStatus(
    //   profileDetailsApiData?.gstRegistrationCertificateUploadStatus
    // );
    // setUdyamRegistrationCertificateUploadStatus(
    //   profileDetailsApiData?.udhyamRegistrationcertificateUploadStatus
    // );
    // setAcceptDeclaration(profileDetailsApiData?.acceptDeclaration);
    // setAcceptTerms(profileDetailsApiData?.acceptTerms);
  }, [
    profileDetailsApiData,
    firmMemberDetailsList,
    allTypesOfFirmsData,
    // cancelledPartnerIndex,
    selectedKycMasterDetails,
    gstinListt,
  ]);
  const openpopup = () => {
    setpopupbtnopen(true);
  };
  const closepopup = (idx) => {
    setaddedpartner(0);
    const temp = [...noOfPartners];
    temp.splice(idx, 1);
    setNoOfPartnerss(temp);
    setpersonalpan(false);
    setaadharpopupsubmit(false);
    setpopupbtnopen(false);
  };

  const openeditpopup = () => {
    seteditpopupbtnopen(true);
  };
  const closeeditpopup = (idx) => {
    setIsEditing(null);
    const temp = [...noOfPartners];
    const retemp = [...noOfPartners];
    temp.splice(idx, 1);
    setNoOfPartnerss(temp);
    setpersonalpan(false);
    setaadharpopupsubmit(false);
    setCancelledPartnerIndex(idx);
    seteditpopupbtnopen(false);
  };
  // console.log("showKycAccess is :", showKycAccess);
  // console.log("Id is ", gstID);
  useEffect(() => {
    console.log("Id is ", gstID);
    console.log("showKycAccess is :", showKycAccess);
    const matchedItem = showKycAccess.find((item) => item.id == gstID);
    console.log("matchedItem", matchedItem);
    if (matchedItem) {
      console.log("Match found:", matchedItem.id, matchedItem.kycStatus);
      setIsKycCompleted(matchedItem?.kycStatus ?? false);
    } else {
      console.log("No match found or no update needed");
    }
  }, [gstID]);

  const getTypeOfFirmObject = () => {
    var propreitorshipFirmObj = {
      id: allTypesOfFirmsData?.propreitorshipFirm?.id ?? null,
      gstRegistrationCertificateUploadStatus: true,
      udhyamRegistrationCertificateUploadStatus: true,
      gstRegistrationCertificateVerificationStatus:
        allTypesOfFirmsData?.propreitorshipFirm
          ?.gstRegistrationCertificateVerificationStatus ?? false,
      udhyamRegistrationCertificateVerificationStatus:
        allTypesOfFirmsData?.propreitorshipFirm
          ?.udhyamRegistrationCertificateVerificationStatus ?? false,
      gstRegistrationCertificateFileName: gstRegistrationCertificateFileName,
      udhyamRegistrationCertificateFileName:
        udyamRegistrationCertificateFileName,
      memberType: "INDIVIDUAL",
      anchortrader: profileObj,
    };
    var llpFirmObj = {
      id: allTypesOfFirmsData?.llpFirm?.id ?? null,
      certificateOfIncorporationUploadStatus: true,
      llpAggrementUploadStatus: true,
      certificateOfIncorporationVerificationStatus:
        allTypesOfFirmsData?.llpFirm
          ?.certificateOfIncorporationVerificationStatus ?? false,
      llpAggrementVerificationStatus:
        allTypesOfFirmsData?.llpFirm?.llpAggrementVerificationStatus ?? false,
      certificateOfIncorporationFileName: certificateOfIncorporationFileName,
      llpAggrementFileName: llpAgreementFileName,
      memberType: "DIRECTOR",
      anchortrader: profileObj,
    };
    var partnershipFirmObj = {
      id: allTypesOfFirmsData?.partnershipFirm?.id ?? null,
      partnershipDeedUploadStatus: true,
      partnershipRegistrationCertificateUploadStatus: true,
      partnershipDeedVerificationStatus:
        allTypesOfFirmsData?.partnershipFirm
          ?.partnershipDeedVerificationStatus ?? false,
      partnershipRegistrationCertificateVerificationStatus:
        allTypesOfFirmsData?.partnershipFirm
          ?.partnershipRegistrationCertificateVerificationStatus ?? false,
      partnershipDeedFileName: partnershipDeedFileName,
      partnershipRegistrationCertificateFileName:
        partnershipRegistrationCertificateFileName,
      memberType: "PARTNER",
      anchortrader: profileObj,
    };
    var limitedCompanyFirmObj = {
      id: allTypesOfFirmsData?.limitedCompanyFirm?.id ?? null,
      memorandumOfAssociationUploadStatus: true,
      articleOfAssociationUploadStatus: true,
      memorandumOfAssociationVerificationStatus:
        allTypesOfFirmsData?.limitedCompanyFirm
          ?.memorandumOfAssociationVerificationStatus ?? false,
      articleOfAssociationVerificationStatus:
        allTypesOfFirmsData?.limitedCompanyFirm
          ?.articleOfAssociationVerificationStatus ?? false,
      memorandumOfAssociationFileName: memorandumOfAssociationFileName,
      articleOfAssociationFileName: articleOfAssociationFileName,
      memberType: "DIRECTOR",
      anchortrader: profileObj,
    };
    if (typeOfFirm === "PROPRIETORSHIP") {
      return {
        propreitorshipFirm: propreitorshipFirmObj,
        partnershipFirm: null,
        llpFirm: null,
        limitedCompanyFirm: null,
      };
    } else if (typeOfFirm === "LLP") {
      return {
        propreitorshipFirm: null,
        partnershipFirm: null,
        llpFirm: llpFirmObj,
        limitedCompanyFirm: null,
      };
    } else if (typeOfFirm === "PARTNERSHIP") {
      return {
        propreitorshipFirm: null,
        partnershipFirm: partnershipFirmObj,
        llpFirm: null,
        limitedCompanyFirm: null,
      };
    } else if (typeOfFirm === "LIMITED_COMPANY") {
      return {
        propreitorshipFirm: null,
        partnershipFirm: null,
        llpFirm: null,
        limitedCompanyFirm: limitedCompanyFirmObj,
      };
    }
  };
  const handleGSTChange = (e) => {
    const inputValue = e.target.value;
    setGstNumber(inputValue);
    setgstnumbervalid(false);
    setgstIsError(inputValue.length < 15);
    if (inputValue.length === 15) {
      const panSubstring = inputValue.slice(2, 12);
      //setPanNumber(panSubstring);
    } else {
      //
    }
  };
  const IFSCLookupfunction = async () => {
    const val = personIfscCode;
    setIsLoading(true);
    try {
      const response = await fetch(`https://ifsc.razorpay.com/${val}`);
      if (response.status === 200) {
        const data = await response.json();
        const address = data.ADDRESS;
        setPersonBankName(data.BANK);
        setPersonBranchName(data.BRANCH);
        setIsLoading(false);
        setOpenSnackbar(true);
        setApiResultMessage("Fetched Bank details successfully !");
        setAlertSeverity("success");
      } else if (response.status === 404) {
        setIsLoading(false);
        setOpenSnackbar(true);
        setAlertSeverity("error");
        setApiResultMessage("IFSC Code not found");
        setPersonBankName(null);
        setPersonBranchName(null);
      } else {
        setIsLoading(false);
        setOpenSnackbar(true);
        setAlertSeverity("error");
        setApiResultMessage("Error Fetching GST Details");
        setPersonBankName(null);
        setPersonBranchName(null);
      }
    } catch (error) {
      setIsLoading(false);
      setOpenSnackbar(true);
      setAlertSeverity("error");
      setApiResultMessage("An error occurred while fetching data");
      setPersonBankName(null);
      setPersonBranchName(null);
    }
  };
  const ValidateGSTNumbers = async () => {
    const inputval = gstNumber;
    setIsLoading(true);
    const constructObj = gstCompanyDetailsContextObjController(
      authContextObj,
      inputval
    );
    const response = await controllerAPI(
      "post",
      auth?.token,
      constructObj,
      "fetch-company-details"
    );
    try {
      if (response.res.status === 200) {
        const rData = response.res.data.data.company.ctb;
        setIsLoading(false);
        setgstapires(rData);
        setTypeOfFirm(theTypeOfFirm(rData));
        setgstnumbervalid(true);
      } else {
        setIsLoading(false);
        setOpenSnackbar(true);
        setAlertSeverity("error");
        setApiResultMessage("Error Fetching GST Details");
      }
    } catch (err) {
      setIsLoading(false);
      setOpenSnackbar(true);
      setAlertSeverity("error");
      setApiResultMessage("An error occurred while fetching data");
    }
  };
  useEffect(() => {
    setIsError(
      noOfPartners?.filter(
        (x) =>
          x.pan === "" ||
          x.panStatus === "" ||
          x.name_as_per_pan === "" ||
          x.date_of_birth === "" ||
          x.aadhar === "" ||
          x.aadharOtp === "" ||
          x.aadharStatus === "" ||
          x.aadharName === "" ||
          x.aadharAddress === ""
      )
    );
  }, [noOfPartners]);
  const handleChange = (idx, type) => (e) => {
    let name;
    let value;
    if (type !== "date") {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "date_of_birth";
      value = e;
    }
    const temp = [...noOfPartners];
    temp[idx] = { ...noOfPartners[idx], [name]: value };
    setNoOfPartnerss(temp);
    setIsChanged(true);
  };

  const handleAADHARChange = (idx, type) => (e) => {
    let name;
    let value;
    if (type !== "date") {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "date_of_birth";
      value = e;
    }
    const temp = [...noOfPartners];
    temp[idx] = { ...noOfPartners[idx], [name]: value };
    setNoOfPartnerss(temp);
    setIsAADHARChanged(true);
  };
  const handleAddRowMissing = () => {
    setaddedpartner(1);
    const item = {
      designation: "PARTNER",
      firstName: "",
      lastName: "",
      firmMemberUlidId: "",
      pan: "",
      panStatus: "",
      name_as_per_pan: "",
      date_of_birth: null,
      aadhar: "",
      aadharOtp: "",
      aadharStatus: "",
      aadharName: "",
      aadharAddress: "",
      anchortrader: profileObj,
      newRecord: true,
      oldRecord: false,
    };

    setNoOfPartnerss([...noOfPartners, item]);
  };

  /*const handleEditRow = (index) => {
    const item = noOfPartnerss[index];
    setEditIndex(index);
    setEditData(item);
    setNoOfPartnerss([...noOfPartners, item]);
   
  };*/

  const handleEditClick = (index) => {
    setIsEditing(index);
    setEditIndex(index);
    const item = noOfPartnerss[index];
    setNoOfPartnerss([item]);
    const newarray = [...noOfPartnerss];
    newarray[index] = noOfPartners;
    setNoOfPartners(newarray);
  };
  const handleRemoveSpecificRow = (idx) => () => {
    setaddedpartner(0);
    const temp = [...noOfPartners];
    temp.splice(idx, 1);
    setNoOfPartnerss(temp);
    setpopupbtnopen(false);
  };

  const handleCancelsepecificRow = (idx) => () => {
    setIsEditing(null);
    const temp = [...noOfPartners];
    const retemp = [...noOfPartners];
    temp.splice(idx, 1);
    setNoOfPartnerss(temp);
    setCancelledPartnerIndex(idx);
    seteditpopupbtnopen(false);
  };

  var profileObj = {
    id: profileDetailsApiData?.id,
    atUlidId: profileDetailsApiData?.atUlidId,
    orgId: profileDetailsApiData?.orgId,
    // tenantId: auth?.userDetails?.tenantId,
    tenantId: profileDetailsApiData?.tenantId,
    customerName: profileDetailsApiData?.customerName,
    orgName: companyName,
    gstId: gstNumber,
    phoneNumber: profileDetailsApiData?.phoneNumber,
    anchorTraderName: profileDetailsApiData?.anchorTraderName,
    location: profileDetailsApiData?.location,
    anchorTraderGST: gstNumber,
    anchorTraderSector: profileDetailsApiData?.anchorTraderSector,
    anchorTraderPAN: panNumber,
    kycCompleted: profileDetailsApiData?.kycCompleted,
    bankDetails: profileDetailsApiData?.bankDetails,
    emailId: companyEmail,
    personalEmailId: profileDetailsApiData?.personalEmailId,
    accountNumber: profileDetailsApiData?.accountNumber,
    ifscCode: profileDetailsApiData?.ifscCode,
    bankName: profileDetailsApiData?.bankName,
    branchName: profileDetailsApiData?.branchName,
    erpAccess: profileDetailsApiData?.erpAccess,
    typeOfFirm: typeOfFirm,
    panStatus: businessPanNumberOTP,
    tosDocument: profileDetailsApiData?.tosDocument,
    //new insertion KYC MASTER
    createdBy: profileDetailsApiData?.organization?.createdBy,
    createdDateTime: profileDetailsApiData?.organization?.createdDateTime,
    lastUpdatedBy: profileDetailsApiData?.organization?.lastUpdatedBy,
    lastUpdatedDateTime:
      profileDetailsApiData?.organization?.lastUpdatedDateTime,
    //
    //acceptTerms: profileDetailsApiData?.acceptTerms,
    //acceptDeclaration: profileDetailsApiData?.acceptDeclaration,
    accountNumber: personAccountNumber,
    ifscCode: personIfscCode,
    bankName: personBankName,
    branchName: personBranchName,
    // gstRegistrationCertificateUploadStatus:
    //   gstRegistrationCertificateUploadStatus,
    // gstRegistrationCertificateVerificationStatus:
    //   profileDetailsApiData?.gstRegistrationCertificateVerificationStatus,
    // udhyamRegistrationcertificateUploadStatus:
    //   udyamRegistrationCertificateUploadStatus,
    // udhyamRegistrationcertificateVerificationStatus:
    //   profileDetailsApiData?.udhyamRegistrationcertificateVerificationStatus,
    kycDeclaration: kycDeclaration,
    organization: profileDetailsApiData.organization,
  };

  var kycMaster = kycList;
  // kycMaster["gstin"]["kycCompleted"] = true;
  kycMaster["companyName"] = companyName;
  kycMaster["bankAccountNumber"] = personAccountNumber;
  kycMaster["bankIFSCCode"] = personIfscCode;
  kycMaster["bankName"] = personBankName;
  kycMaster["branchName"] = personBranchName;
  kycMaster["businessPan"] = panNumber;
  kycMaster["businessPanName"] = companynameperpan;
  kycMaster["businessPanDob"] = dobOfBPan;
  kycMaster["businessPanConsent"] = "Y";
  kycMaster["businessPanReason"] = "For Onboarding";
  kycMaster["businessPanNameAsPerPANMatch"] = businessPanNameAsPerPANMatch;
  kycMaster["businessPANDOBMatch"] = businessPANDOBMatch;
  if (typeOfFirm === "PARTNERSHIP") {
    kycMaster["partnershipDeedFileName"] = partnershipDeedFileName;
    kycMaster["partnershipRegCertFileName"] =
      partnershipRegistrationCertificateFileName;
  } else if (typeOfFirm === "PROPRIETORSHIP") {
    kycMaster["gstRegCertFileName"] = gstRegistrationCertificateFileName;
    kycMaster["udhyamRegCertFileName"] = udyamRegistrationCertificateFileName;
  } else if (typeOfFirm === "LLP") {
    kycMaster["certOfIncorpFileName"] = certificateOfIncorporationFileName;
    kycMaster["llpAggrementFileName"] = llpAgreementFileName;
  } else if (typeOfFirm === "LIMITED_COMPANY") {
    kycMaster["memorandumOfAssocFileName"] = memorandumOfAssociationFileName;
    kycMaster["articleOfAssocFileName"] = articleOfAssociationFileName;
  }

  const saveProfileDetails = async (finalarry1) => {
    setIsEditing(null);
    setIsLoading(true);
    try {
      const constructObj = kycContextObjController(
        authContextObj,
        anchorTraderID,
        profileObj,
        finalarry1,
        kycMaster,
        gstID
      );
      const saveProfileDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "update-kyc-profile-details"
      );

      if (saveProfileDetailsResponse.res.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Saved KYC Details Successfully");
        setAlertSeverity("success");
        // Reload the page
        //window.location.reload();
      }
    } catch (err) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Occurred while saving KYC Details");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChang = (type) => (e) => {
    let name;
    let value;
    setbpanchange(false);
    if (type === "date") {
      name = "date_of_birth";
      value = e;
      setDobOfPan(e);
    } else if (type === "pan") {
      name = e.target.name;
      value = e.target.value;
      setPanNumber(e.target.value);
    } else {
      name = e.target.name;
      value = e.target.value;
      setCompanyNamechange(true);
      setCompanynameperpan(e.target.value);
    }
    setbusinesspanarray((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePanNumberChange = (event) => {
    setPanNumber(event.target.value);
  };
  const validateBPanDetails = async (value) => {
    setIsLoading(true);
    const newDate = new Date(value.date_of_birth);
    const month = newDate.getMonth() + 1;
    const twoDigitMonth = month.toString().padStart(2, "0");
    let day = newDate.getDate();
    let twoDigitDate = day.toString().padStart(2, "0");
    let year = newDate.getFullYear();
    const finalDate = `${twoDigitDate}/${twoDigitMonth}/${year}`;
    const panObject = {
      "@entity": "in.co.sandbox.kyc.pan_verification.request",
      name_as_per_pan: value.name_as_per_pan,
      date_of_birth: finalDate,
      pan: value.pan,
      consent: "Y",
      reason: "For onboarding customers",
    };
    try {
      const constructObj = panValidateContextObjController(
        authContextObj,
        anchorTraderID,
        gstID,
        panObject
      );
      const validatePanDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "validate-pan"
      );
      if (validatePanDetailsResponse.res.status === 200) {
        const pandat = validatePanDetailsResponse.res.data.data.data;
        //setIsChanged(false);
        if (
          pandat.category.toLowerCase() === "company" ||
          pandat.category.toLowerCase() === "firm"
        ) {
          if (
            pandat.status.toLowerCase() === "valid" &&
            pandat.name_as_per_pan_match === true &&
            pandat.date_of_birth_match === true
          ) {
            setbpanchange(true);
            setOpenSnackbar(true);
            setApiResultMessage("Fetched Pan Details Successfully");
            setAlertSeverity("success");
            setBusinessPanNumberOTP(true);
            setBusinessPanNameAsPerPANMatch(true);
            setBusinessPANDOBMatch(true);
          } else if (
            pandat.status.toLowerCase() === "valid" &&
            pandat.name_as_per_pan_match === false &&
            pandat.date_of_birth_match === true
          ) {
            setOpenSnackbar(true);
            setApiResultMessage(
              "Error Fetching PAN Details. Wrong Company Name Entered"
            );
            setAlertSeverity("error");
            setBusinessPanNumberOTP(false);

            setBusinessPanNameAsPerPANMatch(false);
            setBusinessPANDOBMatch(true);
          } else if (
            pandat.status.toLowerCase() === "valid" &&
            pandat.name_as_per_pan_match === true &&
            pandat.date_of_birth_match === false
          ) {
            setOpenSnackbar(true);
            setApiResultMessage(
              "Error Fetching PAN Details. Wrong Date of Inauguration Entered"
            );
            setAlertSeverity("error");
            setBusinessPanNumberOTP(false);

            setBusinessPanNameAsPerPANMatch(true);
            setBusinessPANDOBMatch(false);
          } else if (
            pandat.status.toLowerCase() === "valid" &&
            pandat.name_as_per_pan_match === false &&
            pandat.date_of_birth_match === false
          ) {
            setOpenSnackbar(true);
            setApiResultMessage(
              "Error Fetching PAN Details. Wrong Company Name & Date of Inauguration Entered"
            );
            setAlertSeverity("error");
            setBusinessPanNumberOTP(false);

            setBusinessPanNameAsPerPANMatch(false);
            setBusinessPANDOBMatch(false);
          } else {
            setOpenSnackbar(true);
            setApiResultMessage(
              "Error Fetching PAN Details. Wrong Business PAN Entered"
            );
            setAlertSeverity("error");
            setBusinessPanNumberOTP(false);
          }
        } else {
          setOpenSnackbar(true);
          setApiResultMessage("Error Fetching PAN Details,Not Company PAN");
          setAlertSeverity("error");
          setBusinessPanNumberOTP(false);
        }
      }
    } catch (err) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Fetching Pan Details");
      setAlertSeverity("error");
      setBusinessPanNumberOTP(false);
    } finally {
      setIsLoading(false);
    }
  };

  const validatePanDetails = async (value, index) => {
    setIsLoading(true);
    const newDate = new Date(value.date_of_birth);
    const month = newDate.getMonth() + 1;
    const twoDigitMonth = month.toString().padStart(2, "0");
    let day = newDate.getDate();
    let twoDigitDate = day.toString().padStart(2, "0");
    let year = newDate.getFullYear();
    const finalDate = `${twoDigitDate}/${twoDigitMonth}/${year}`;
    const panObject = {
      "@entity": "in.co.sandbox.kyc.pan_verification.request",
      name_as_per_pan: value.name_as_per_pan,
      date_of_birth: finalDate,
      pan: value.pan,
      consent: "Y",
      reason: "For onboarding customers",
    };
    try {
      const constructObj = panValidateContextObjController(
        authContextObj,
        anchorTraderID,
        gstID,
        panObject
      );
      const validatePanDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "validate-pan"
      );
      if (validatePanDetailsResponse.res.status === 200) {
        setpanData(validatePanDetailsResponse.res.data.data.data);
        const updatedPartners = [...noOfPartners];
        updatedPartners[index].newRecord = false; // Marking as not new after validation
        setNoOfPartnerss(updatedPartners);
        updatedPartners[index].newRecord = false;
        const pandat = validatePanDetailsResponse.res.data.data.data;
        setIsChanged(false);
        if (
          pandat.status.toLowerCase() === "valid" &&
          pandat.name_as_per_pan_match === true &&
          pandat.date_of_birth_match === true
        ) {
          const temp = [...noOfPartners];
          temp[index] = {
            ...noOfPartners[index],
            panStatus: pandat.status.toLowerCase() === "valid" ? true : false,
            name: pandat.last_name + " " + pandat.first_name,
          };
          setNoOfPartnerss(temp);
          setOpenSnackbar(true);
          setApiResultMessage("Fetched Pan Details Successfully");
          setpersonalpan(true);
          setAlertSeverity("success");
        } else if (
          pandat.status.toLowerCase() === "valid" &&
          pandat.name_as_per_pan_match === false &&
          pandat.date_of_birth_match === true
        ) {
          const temp = [...noOfPartners];
          temp[index] = {
            ...noOfPartners[index],
            panStatus: pandat.status == false,
            name: pandat.last_name + " " + pandat.first_name,
          };
          setNoOfPartnerss(temp);
          setOpenSnackbar(true);
          setApiResultMessage("Error Fetching PAN Details. Wrong Name Entered");
          setAlertSeverity("error");
        } else if (
          pandat.status.toLowerCase() === "valid" &&
          pandat.name_as_per_pan_match === true &&
          pandat.date_of_birth_match === false
        ) {
          const temp = [...noOfPartners];
          temp[index] = {
            ...noOfPartners[index],
            panStatus: pandat.status == false,
            name: pandat.last_name + " " + pandat.first_name,
          };
          setNoOfPartnerss(temp);
          setOpenSnackbar(true);
          setApiResultMessage("Error Fetching PAN Details. Wrong DOB Entered");
          setAlertSeverity("error");
        } else if (
          pandat.status.toLowerCase() === "valid" &&
          pandat.name_as_per_pan_match === false &&
          pandat.date_of_birth_match === false
        ) {
          const temp = [...noOfPartners];
          temp[index] = {
            ...noOfPartners[index],
            panStatus: pandat.status == false,
            name: pandat.last_name + " " + pandat.first_name,
          };
          setNoOfPartnerss(temp);
          setOpenSnackbar(true);
          setApiResultMessage(
            "Error Fetching PAN Details. Wrong Name and DOB Entered"
          );
          setAlertSeverity("error");
        } else {
          const temp = [...noOfPartners];
          temp[index] = {
            ...noOfPartners[index],
            panStatus: pandat.status == false,
            name: pandat.last_name + " " + pandat.first_name,
          };
          setNoOfPartnerss(temp);
          setOpenSnackbar(true);
          setApiResultMessage("Error Fetching PAN Details. Wrong PAN Entered");
          setAlertSeverity("error");
        }
      } else {
        const temp = [...noOfPartners];
        temp[index] = {
          ...noOfPartners[index],
          panStatus: "",
          name: "",
        };
        setNoOfPartnerss(temp);
      }
    } catch (err) {
      const temp = [...noOfPartners];
      temp[index] = {
        ...noOfPartners[index],
        panStatus: "",
        name: "",
      };
      setNoOfPartnerss(temp);
      setOpenSnackbar(true);
      setApiResultMessage("Error Fetching Pan Details");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const generateOTP = async (value) => {
    setIsLoading(true);
    const otpObject = {
      "@entity": "in.co.sandbox.kyc.aadhaar.okyc.otp.request",
      consent: "Y",
      reason: "Aadhar Validation",
      aadhaar_number: value,
    };
    try {
      const constructObj = generateOTPContextObjController(
        authContextObj,
        anchorTraderID,
        otpObject
      );
      const validatePanDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "generate-otp"
      );

      if (validatePanDetailsResponse.res.status === 200) {
        if (validatePanDetailsResponse.res.data.data.data.ref_id) {
          setAllAadharResponses([
            ...allAadharResponses,
            {
              aadharNumber: value,
              refId: validatePanDetailsResponse?.res?.data?.data?.data?.ref_id,
            },
          ]);
          setOpenSnackbar(true);
          setApiResultMessage("OTP Generated Successfully");
          setAlertSeverity("success");
        } else {
          setOpenSnackbar(true);
          setApiResultMessage("Please Enter Correct Aadhaar Number");
          setAlertSeverity("error");
        }
      }
    } catch (err) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured while generating OTP");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOTP = async (otp, aadharNumber, index) => {
    setIsLoading(true);
    const t = allAadharResponses?.filter(
      (x) => x.aadharNumber === aadharNumber
    );
    const filteredRefId = t[t?.length - 1]?.refId;
    const otpObject = {
      // "@entity": "in.co.sandbox.kyc.aadhaar.okyc.request",
      otp: otp,
      ref_id: filteredRefId,
    };
    try {
      const constructObj = verifyOTPContextObjController(
        authContextObj,
        anchorTraderID,
        gstID,
        otpObject
      );
      const validateAadharDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "validate-otp"
      );

      if (validateAadharDetailsResponse.res.status === 200) {
        const updatedPartners = [...noOfPartners];
        updatedPartners[index].newRecord = false; // Marking as not new after validation
        setNoOfPartnerss(updatedPartners);
        updatedPartners[index].newRecord = false;
        const aadharData = validateAadharDetailsResponse.res.data.data.data;
        if (aadharData.status === "VALID") {
          const temp = [...noOfPartners];
          temp[index] = {
            ...noOfPartners[index],
            aadharStatus: aadharData.status === "VALID" ? true : false,
            aadharName: aadharData.name,
            aadharAddress: aadharData.address,
          };
          setNoOfPartnerss(temp);
          setotpverified(true);
          setOpenSnackbar(true);
          setApiResultMessage("OTP Verified Successfully");
          setIsAADHARChanged(false);
          setAlertSeverity("success");
          setaadharpopupsubmit(true);
        } else {
          const temp = [...noOfPartners];
          temp[index] = {
            ...noOfPartners[index],
            aadharStatus: aadharData.status == false,
            aadharName: "",
            aadharAddress: "",
          };
          setNoOfPartnerss(temp);

          setOpenSnackbar(true);
          setApiResultMessage("Wrong OTP Entered");
          setAlertSeverity("error");
        }
      } else {
        const temp = [...noOfPartners];
        temp[index] = {
          ...noOfPartners[index],
          aadharStatus: false,
          aadharName: "",
          aadharAddress: "",
        };
        setNoOfPartnerss(temp);
      }
    } catch (err) {
      const temp = [...noOfPartners];
      temp[index] = {
        ...noOfPartners[index],
        aadharStatus: false,
        aadharName: "",
        aadharAddress: "",
      };
      setNoOfPartnerss(temp);

      setOpenSnackbar(true);
      setApiResultMessage("Wrong OTP Entered");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFile = async (file, type, typeOfFirm) => {
    setIsLoading(true);
    try {
      const constructObj = {};
      const saveProfileDetailsResponse = await controllerAPIForFileUpload(
        "post",
        auth?.token,
        file,
        type,
        new Date().toISOString(),
        auth?.userDetails?.username,
        auth?.userDetails?.tenantId,
        anchorTraderID,
        1500,
        typeOfFirm,
        kycMasterId
      );

      if (saveProfileDetailsResponse.res.status === 200) {
        type === "upload-gst-registration" && setSelectedFileGST(file);
        type === "upload-udhyam-registration" && setSelectedFileUdyam(file);
        type === "upload-gst-registration" &&
          setGstRegistrationCertificateUploadStatus(true);
        type === "upload-udhyam-registration" &&
          setUdyamRegistrationCertificateUploadStatus(true);
        setOpenSnackbar(true);
        setApiResultMessage("File Uploaded Successfully");
        setAlertSeverity("success");
      }
    } catch (err) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured while uploading file");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const changeHandlerForUploadGST = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 10 * 1000 * 1024) {
        setOpenSnackbar(true);
        setApiResultMessage("Please Upload File less than 10 MB Size.");
        setAlertSeverity("error");
        event.target.value = null;
        return false;
      } else {
        setVisibleFileName(true);
        const file = event.target.files[0];
        // const { name } = file;
        typeOfFirm === "LIMITED_COMPANY" &&
          setMemorandumOfAssociationFileName(file.name);
        typeOfFirm === "LLP" &&
          setCertificateOfIncorporationFileName(file.name);
        typeOfFirm === "PROPRIETORSHIP" &&
          setGstRegistrationCertificateFileName(file.name);
        typeOfFirm === "PARTNERSHIP" && setPartnershipDeedFileName(file.name);
        uploadFile(
          event.target.files[0],
          "upload-gst-registration",
          typeOfFirm
        );
      }
    }
  };
  const changeHandlerForUploadUdyam = (event, type) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 10 * 1000 * 1024) {
        setOpenSnackbar(true);
        setApiResultMessage("Please Upload File less than 10 MB Size.");
        setAlertSeverity("error");
        event.target.value = null;
        return false;
      } else {
        setVisibleFileNameTwo(true);
        const file = event.target.files[0];
        // const { name } = file;
        typeOfFirm === "LIMITED_COMPANY" &&
          setArticleOfAssociationFileName(file.name);
        typeOfFirm === "LLP" && setLlpAgreementFileName(file.name);
        typeOfFirm === "PROPRIETORSHIP" &&
          setUdyamRegistrationCertificateFileName(file.name);
        typeOfFirm === "PARTNERSHIP" &&
          setPartnershipRegistrationCertificateFileName(file.name);
        uploadFile(
          event.target.files[0],
          "upload-udhyam-registration",
          typeOfFirm
        );
      }
    }
  };

  const handleSubmit = async (e) => {
    setaddedpartner(0);
    const noOfPartnerssflat = noOfPartnerss?.flatMap((x) => x);
    const finalarrya = [...noOfPartners, noOfPartnerssflat];
    const finalarry1 = finalarrya?.flatMap((x) => x);
    e.preventDefault();

    if (finalarry1.length > 0) {
      isError?.length === 0 && saveProfileDetails(finalarry1);
    } else {
      setOpenSnackbar(true);
      setAlertSeverity("error");
      setApiResultMessage(
        `Add at least one${
          typeOfFirm === "PROPRIETORSHIP"
            ? " Individual"
            : typeOfFirm === "PARTNERSHIP"
            ? " Partner"
            : typeOfFirm === "LLP"
            ? " Director"
            : typeOfFirm === "LIMITED_COMPANY"
            ? " Director"
            : " Person"
        }`
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const handleBpanValidation = async (e) => {
    setBusinessPanNumberStatus(2);
    setBusinessPanNumberOTPSent(1);
  };
  const handleBotpValidation = async (e) => {
    setBusinessPanNumberStatus(1);
    setBusinessPanNumberOTPSent(0);
  };
  const [universalclick, setuniversalclick] = useState(false);
  // const editbtnclick = (e) => {
  //   e.preventDefault();
  //   setPopupBtnOpenEditAccess(true);
  //   //setuniversalclick(true);
  // };
  const handleOtpForVerification = (e) => {
    const inputt = e.target.value;
    setOtpEditAccess(inputt);
  };
  const handleEditAccessRestriction = () => {
    const otp = "234356";
    if (otpEditAccess == otp) {
      setuniversalclick(true);
      setOpenSnackbar(true);
      setApiResultMessage("OTP verified");
      setAlertSeverity("success");
      closepopupEditAccess();
    } else {
      setOpenSnackbar(true);
      setApiResultMessage("Wrong OTP entered. Please try again.");
      setAlertSeverity("error");
    }
  };

  const openpopupEditAccess = () => {
    setPopupBtnOpenEditAccess(true);
  };
  const closepopupEditAccess = () => {
    setPopupBtnOpenEditAccess(false);
  };

  const cancelclick = () => {
    setuniversalclick(false);
  };

  const handleDropDownChange = (event) => {
    const [selectedId, selectedGstId] = event.target.value.split("|");
    setGstID(selectedId);
    setGstNumber(selectedGstId);
    getErpAccessDetails(selectedId);
  };
  const isButtonDisabled =
    !universalclick ||
    panNumber?.length === 0 ||
    businesspanarray?.name_as_per_pan?.length === 0 ||
    companynamechange !== true;
  return (
    <div
      className="body-text"
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <form
        className="form"
        onSubmit={handleSubmit}
        style={{ width: "1200px" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "row",
                  gap: "50px",
                }}
              >
                This KYC process is a one-time process to comply with RBI
                guidelines. Please follow these steps to complete the KYC
                process.
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    sx={{ minWidth: 100, width: "180px", margin: "0px 20px" }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        //style={{ backgroundColor: "black" }}
                        variant="standard"
                        htmlFor="uncontrolled-native"
                      >
                        GST ID
                      </InputLabel>
                      <NativeSelect
                        inputProps={{
                          name: "GSTID",
                          id: "uncontrolled-native",
                        }}
                        value={`${gstID}|${gstNumber}`}
                        onChange={handleDropDownChange}
                      >
                        {!showSingleGst &&
                          gstinListt?.map((item, index) => (
                            <option
                              key={index}
                              value={`${item.id}|${item.gstId}`}
                            >
                              {item.gstId}
                            </option>
                          ))}
                        {showSingleGst &&
                          gstinListt
                            ?.filter(
                              (item) =>
                                item.id ===
                                selectedKycMasterDetails?.res?.data?.data
                                  ?.kycMaster?.gstin?.id
                            )
                            .map((item, index) => (
                              <option
                                key={index}
                                value={`${item.id}|${item.gstId}`}
                              >
                                {item.gstId}
                              </option>
                            ))}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                  {console.log(isKycCompleted, "isKycCompleted")}
                  <button
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      padding: "8px 8px",
                      cursor: "pointer",
                      height: "fit-content",
                      boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                      opacity:
                        isKycCompleted == true || universalclick ? "0.5" : "1", // Set opacity based on isKycCompleted
                      width: "90px",
                      marginRight: universalclick ? "10px" : "0",
                    }}
                    onClick={() => setuniversalclick(true)} // Corrected onClick function
                    disabled={isKycCompleted == true} // Disable if true
                  >
                    Edit
                  </button>

                  <Dialog
                    open={popupbtnopeneditaccess}
                    onClose={closepopupEditAccess}
                    fullWidth
                    maxWidth="sm"
                    PaperProps={{
                      style: { borderRadius: 20 },
                    }}
                  >
                    <DialogTitle>Verify OTP </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={closepopupEditAccess}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Divider />
                    <DialogContent>
                      {isLoading && (
                        <CircularProgress
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            zIndex: "1100",
                          }}
                        />
                      )}
                      <DialogContentText
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TextField
                          inputProps={{ style: { fontSize: 14 } }}
                          InputLabelProps={{
                            sx: {
                              color: "#4A3AFF",
                              fontWeight: "bold",
                              [`&.${inputLabelClasses.shrink}`]: {
                                color: "#4A3AFF",
                              },
                            },
                          }}
                          style={{
                            width: "250px",
                            margin: "5px",
                          }}
                          id="standard-basic"
                          label="OTP "
                          name={"OTP"}
                          variant="outlined"
                          onChange={handleOtpForVerification}
                          required
                        />
                        <button
                          type="submit"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#4A3AFF",
                            color: "white",
                            border: "none",
                            padding: "8px 8px",
                            cursor: "pointer",
                            height: "fit-content",
                            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                            width: "100px",
                            justifyContent: "center",
                          }}
                          onClick={handleEditAccessRestriction}
                        >
                          Verify
                        </button>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions></DialogActions>
                  </Dialog>

                  {universalclick && (
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "8px 8px",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                      }}
                      onClick={cancelclick}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
              <div>
                <div
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    marginTop: "50px",
                  }}
                >
                  Business Verification :
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "15px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      margin: "12px",
                    }}
                  >
                    1) GST Verification:
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "0",
                        position: "relative",
                      }}
                    >
                      <TextField
                        inputProps={{ style: { fontSize: 14 }, maxLength: 15 }}
                        InputLabelProps={{
                          sx: {
                            color: "#4A3AFF",
                            fontWeight: "bold",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#4A3AFF",
                            },
                          },
                        }}
                        style={{
                          width: "250px",
                        }}
                        id="standard-basic"
                        label="GST Number"
                        value={gstNumber}
                        onChange={handleGSTChange}
                        variant="outlined"
                        required
                        error={isgstError}
                        helperText={
                          isgstError ? "GST Number must be 15 digits long" : ""
                        }
                        disabled
                      />
                      {gstnumbervalid === true ? (
                        <div
                          style={{
                            position: "absolute",
                            right: "25px",
                            top: "100%",
                            transform: "translateY(-50%)",
                            width: "70px",
                            height: "90px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={VERFIED}
                            alt="Verified"
                            style={{
                              width: "200%",
                              height: "200%",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      ) : (
                        <button
                          type="button"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            borderRadius: "5px",
                            background: "rgb(74, 58, 255)",
                            color: "white",
                            border: "none",
                            padding: "5px 15px",
                            cursor: "pointer",
                            height: "45px",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity:
                              gstNumber?.length < 15 || universalclick !== true
                                ? 0.5
                                : 1,
                          }}
                          onClick={ValidateGSTNumbers}
                          disabled={
                            gstNumber?.length < 15 || universalclick !== true
                          }
                        >
                          Validate
                        </button>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <TextField
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{
                          sx: {
                            color: "#4A3AFF",
                            fontWeight: "bold",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#4A3AFF",
                            },
                          },
                        }}
                        style={{
                          width: "250px",
                        }}
                        id="standard-basic"
                        label="Type of firm"
                        value={value}
                        variant="outlined"
                        readOnly
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{
                          sx: {
                            color: "#4A3AFF",
                            fontWeight: "bold",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#4A3AFF",
                            },
                          },
                        }}
                        style={{
                          width: "250px",
                        }}
                        id="standard-basic"
                        label="Email address"
                        value={companyEmail}
                        onChange={(e) => setCompanyEmail(e.target.value)}
                        variant="outlined"
                        required
                        disabled={universalclick !== true}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{
                          sx: {
                            color: "#4A3AFF",
                            fontWeight: "bold",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#4A3AFF",
                            },
                          },
                        }}
                        style={{
                          width: "250px",
                        }}
                        id="standard-basic"
                        label="Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        variant="outlined"
                        required
                        disabled={universalclick !== true}
                      />
                    </div>
                  </div>

                  {typeOfFirm == "PROPRIETORSHIP" ? (
                    <></>
                  ) : (
                    <>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          margin: "12px",
                        }}
                      >
                        2) PAN Verification:
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <TextField
                            inputProps={{ style: { fontSize: 14 } }}
                            InputLabelProps={{
                              sx: {
                                color: "#4A3AFF",
                                fontWeight: "bold",
                                [`&.${inputLabelClasses.shrink}`]: {
                                  color: "#4A3AFF",
                                },
                              },
                            }}
                            style={{
                              width: "250px",
                            }}
                            id="standard-basic"
                            label="Company Full Name"
                            name={"name_as_per_pan"}
                            value={companynameperpan} //
                            onChange={handleChang("name")}
                            variant="outlined"
                            required={businessPanNumberOTP === false}
                            disabled={universalclick !== true}
                          />
                        </div>
                        <div
                          style={{
                            width: "250px",
                          }}
                        >
                          <DatePicker
                            inputProps={{ style: { fontSize: 10 } }}
                            InputLabelProps={{
                              sx: {
                                color: "#4A3AFF",
                                fontWeight: "bold",
                                [`&.${inputLabelClasses.shrink}`]: {
                                  color: "#4A3AFF",
                                },
                              },
                            }}
                            sx={{
                              width: "250px",
                            }}
                            label="Date of Inauguration*"
                            name="date_of_birth"
                            onChange={handleChang("date")}
                            required
                            //value={dobOfBPan || null} // Ensure value is a valid date or null
                            disabled={universalclick !== true}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <TextField
                            inputProps={{
                              style: { fontSize: 14 },
                              maxLength: 10,
                            }}
                            InputLabelProps={{
                              sx: {
                                color: "#4A3AFF",
                                fontWeight: "bold",
                                [`&.${inputLabelClasses.shrink}`]: {
                                  color: "#4A3AFF",
                                },
                              },
                            }}
                            sx={{
                              width: "250px",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor:
                                    businessPanNumberOTP === false ? "red" : "",
                                },
                              },
                              "& .MuiInputBase-input::placeholder": {
                                color: "red",
                                opacity: 1,
                              },
                            }}
                            id="standard-basic"
                            label="PAN"
                            placeholder="Enter PAN Number"
                            value={panNumber}
                            name={"pan"}
                            onChange={handleChang("pan")}
                            variant="outlined"
                            required
                            disabled={universalclick !== true}
                          />
                          {bpanchanged !== true ? (
                            <button
                              type="button"
                              style={{
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                                borderRadius: "5px",
                                background: "rgb(74, 58, 255)",
                                color: "white",
                                border: "none",
                                padding: "5px 15px",
                                cursor: isButtonDisabled
                                  ? "not-allowed"
                                  : "pointer",
                                height: "45px",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: isButtonDisabled ? 0.5 : 1,
                                pointerEvents: isButtonDisabled
                                  ? "none"
                                  : "auto",
                              }}
                              onClick={() =>
                                validateBPanDetails(businesspanarray)
                              }
                              disabled={isButtonDisabled} // Fallback for disabling the button
                              aria-disabled={isButtonDisabled} // Accessibility enhancement
                            >
                              Validate
                            </button>
                          ) : (
                            <div
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "100%",
                                transform: "translateY(-50%)",
                                width: "100px",
                                height: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={VERFIED}
                                alt="Verified"
                                style={{
                                  width: "200%",
                                  height: "200%",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <TextField
                            inputProps={{ style: { fontSize: 14 } }}
                            InputLabelProps={{
                              sx: {
                                color: "#4A3AFF",
                                fontWeight: "bold",
                                [`&.${inputLabelClasses.shrink}`]: {
                                  color: "#4A3AFF",
                                },
                              },
                            }}
                            style={{
                              width: "250px",
                            }}
                            id="standard-basic"
                            label="PAN Status"
                            value={businessPanNumberOTP}
                            variant="outlined"
                            placeholder="PAN Status"
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  marginTop: "20px",
                }}
              >
                Account Details:
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "0",
                      position: "relative",
                    }}
                  >
                    <TextField
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      id="standard-basic"
                      label="Account number"
                      value={personAccountNumber}
                      onChange={(e) => setPersonAccountNumber(e.target.value)}
                      variant="outlined"
                      type="number"
                      style={{
                        width: "250px",
                      }}
                      required
                      disabled={universalclick !== true}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "0",
                      position: "relative",
                    }}
                  >
                    <TextField
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        shrink: Boolean(personIfscCode),
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      id="standard-basic"
                      label="IFSC code"
                      value={personIfscCode}
                      onChange={(e) =>
                        setPersonIfscCode(e.target.value.replace(/\s/g, ""))
                      }
                      variant="outlined"
                      required
                      style={{
                        width: "250px",
                      }}
                      disabled={universalclick !== true}
                    />

                    <button
                      type="button"
                      style={{
                        position: "absolute",
                        right: "5px",
                        borderRadius: "5px",
                        background: "rgb(74, 58, 255)",
                        color: "white",
                        border: "none",
                        padding: "5px 15px",
                        cursor: "pointer",
                        height: "45px",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity:
                          universalclick !== true ||
                          personIfscCode?.length === 0
                            ? 0.5
                            : 1,
                      }}
                      disabled={
                        personIfscCode?.length === 0 || universalclick !== true
                      }
                      onClick={IFSCLookupfunction}
                    >
                      Fetch
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      id="standard-basic"
                      label="Bank name"
                      value={personBankName}
                      onChange={(e) => setPersonBankName(e.target.value)}
                      variant="outlined"
                      required
                      style={{
                        width: "250px",
                      }}
                      disabled={universalclick !== true}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <TextField
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      id="standard-basic"
                      label="Branch name"
                      value={personBranchName}
                      onChange={(e) => setPersonBranchName(e.target.value)}
                      variant="outlined"
                      required
                      style={{
                        width: "250px",
                      }}
                      disabled={universalclick !== true}
                    />
                  </div>
                </div>
              </div>

              <div>
                {typeOfFirm && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "50px",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    <label
                      style={{
                        width: "250px",
                        color: "#4A3AFF",
                        fontWeight: "bold",
                      }}
                    >
                      {typeOfFirm === "PROPRIETORSHIP"
                        ? "GST registration certificate *"
                        : typeOfFirm === "PARTNERSHIP"
                        ? "Partnership Deed *"
                        : typeOfFirm === "LLP"
                        ? "Certificate of Incorporation *"
                        : typeOfFirm === "LIMITED_COMPANY"
                        ? "Memorandum of Association *"
                        : ""}
                    </label>
                    <input
                      type="file"
                      id="gst-file-upload"
                      accept="application/pdf"
                      required
                      onChange={changeHandlerForUploadGST}
                      style={{
                        opacity: universalclick !== true ? "0.5" : "1",
                      }}
                      disabled={universalclick !== true}
                    />
                    <span style={{ color: "#3F4F5A" }}>
                      {gstRegistrationCertificateUploadStatus &&
                        !gstRegistrationCertificateVerificationStatus &&
                        "Uploaded"}
                    </span>
                    <span style={{ color: "#00692A" }}>
                      {gstRegistrationCertificateUploadStatus &&
                        gstRegistrationCertificateVerificationStatus &&
                        "Verified"}
                    </span>
                    {visibleFileName === true ? (
                      <></>
                    ) : (
                      <>
                        {typeOfFirm === "PARTNERSHIP" && (
                          <span> {partnershipDeedFileName}</span>
                        )}
                        {typeOfFirm === "PROPRIETORSHIP" && (
                          <span>{gstRegistrationCertificateFileName}</span>
                        )}
                        {typeOfFirm === "LLP" && (
                          <span>{certificateOfIncorporationFileName}</span>
                        )}
                        {typeOfFirm === "LIMITED_COMPANY" && (
                          <span>{memorandumOfAssociationFileName}</span>
                        )}
                      </>
                    )}
                  </div>
                )}

                {typeOfFirm && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2%",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    <label
                      style={{
                        width: "250px",
                        color: "#4A3AFF",
                        fontWeight: "bold",
                      }}
                    >
                      {typeOfFirm === "PROPRIETORSHIP"
                        ? "Udyam Registration Certificate *"
                        : typeOfFirm === "PARTNERSHIP"
                        ? "Partnership Registration Certificate*"
                        : typeOfFirm === "LLP"
                        ? "LLP Agreement *"
                        : typeOfFirm === "LIMITED_COMPANY"
                        ? "Article of Association *"
                        : ""}
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      accept="application/pdf"
                      required
                      onChange={changeHandlerForUploadUdyam}
                      style={{
                        opacity: universalclick !== true ? "0.5" : "1",
                      }}
                      disabled={universalclick !== true}
                    />
                    <span style={{ color: "#3F4F5A" }}>
                      {udyamRegistrationCertificateUploadStatus &&
                        !udyamRegistrationCertificateVerificationStatus &&
                        "Uploaded"}
                    </span>
                    <span style={{ color: "#00692A" }}>
                      {udyamRegistrationCertificateUploadStatus &&
                        udyamRegistrationCertificateVerificationStatus &&
                        "Verified"}
                    </span>
                    {visibleFileNameTwo === true ? (
                      <></>
                    ) : (
                      <>
                        {" "}
                        {typeOfFirm === "PARTNERSHIP" && (
                          <span>
                            {partnershipRegistrationCertificateFileName}
                          </span>
                        )}
                        {typeOfFirm === "PROPRIETORSHIP" && (
                          <span>{udyamRegistrationCertificateFileName}</span>
                        )}
                        {typeOfFirm === "LLP" && (
                          <span> {llpAgreementFileName}</span>
                        )}
                        {typeOfFirm === "LIMITED_COMPANY" && (
                          <span> {articleOfAssociationFileName}</span>
                        )}
                      </>
                    )}

                    {/* Conditionally render the uploaded file name */}
                    {/* {udyamFileName && <span>Uploaded File: {udyamFileName}</span>} */}
                  </div>
                )}
              </div>

              {partnertabledetails?.length > 0 ? (
                <>
                  <h1>
                    {typeOfFirm == "PROPRIETORSHIP"
                      ? " Individual "
                      : typeOfFirm == "PARTNERSHIP"
                      ? " Partner "
                      : typeOfFirm == "LLP"
                      ? " Director "
                      : typeOfFirm == "LIMITED_COMPANY"
                      ? " Director "
                      : " Person"}{" "}
                    Details
                  </h1>
                  <div
                    style={{
                      width: "50%",
                      color: "#000",
                      fontWeight: "bold",
                      display: "flex",
                      margin: "15px 0px",
                    }}
                  >
                    Personal Verification by Individual KYC :{" "}
                    {typeOfFirm == "Proprietorship" &&
                    noOfPartners?.length >= 1 ? (
                      <></>
                    ) : (
                      <>
                        <button
                          id="add-person"
                          type="button"
                          style={{
                            marginLeft: "10px",
                            borderRadius: "5px",
                            backgroundColor: "#4A3AFF",
                            color: "white",
                            border: "none",
                            padding: "5px 15px",
                            cursor: "pointer",
                            height: "fit-content",
                            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                            opacity:
                              isEditing !== null ||
                              addpartner !== 0 ||
                              universalclick !== true
                                ? "0.5"
                                : "1",
                            // opacity: "0.5",
                          }}
                          onClick={() => {
                            handleAddRowMissing();
                            openpopup();
                          }}
                          disabled={
                            isEditing !== null ||
                            addpartner !== 0 ||
                            universalclick !== true
                          }
                        >
                          Add
                          {typeOfFirm == "PROPRIETORSHIP"
                            ? " Individual "
                            : typeOfFirm == "PARTNERSHIP"
                            ? " Partner "
                            : typeOfFirm == "LLP"
                            ? " Director "
                            : typeOfFirm == "LIMITED_COMPANY"
                            ? " Director "
                            : " Person"}
                        </button>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        margin: "1px",
                        borderCollapse: "collapse",
                        padding: "10px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: "5%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            S.No.
                          </th>
                          <th
                            style={{
                              width: "20%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            Name as per Aadhaar
                          </th>
                          <th
                            style={{
                              width: "15%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            PAN
                          </th>
                          <th
                            style={{
                              width: "15%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            Designation
                          </th>
                          <th
                            style={{
                              width: "15%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            Aadhaar
                          </th>
                          <th
                            style={{
                              width: "20%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            Address
                          </th>
                          <th
                            style={{
                              width: "10%",
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {partnertabledetails?.map((item, index) => (
                          <tr key={index} style={{ border: "1px solid black" }}>
                            <td style={{ padding: "8px" }}>{index + 1}</td>
                            <td style={{ padding: "8px" }}>
                              {item.aadharName}
                            </td>
                            <td style={{ padding: "8px" }}>{item.pan}</td>
                            <td style={{ padding: "8px" }}>
                              {typeOfFirm == "PROPRIETORSHIP"
                                ? " Individual "
                                : typeOfFirm == "PARTNERSHIP"
                                ? " Partner "
                                : typeOfFirm == "LLP"
                                ? " Director "
                                : typeOfFirm == "LIMITED_COMPANY"
                                ? " Director "
                                : " Person"}{" "}
                              {index + 1}
                            </td>
                            <td style={{ padding: "8px" }}>{item.aadhar}</td>
                            <td
                              style={{
                                padding: "8px",
                                maxWidth: "200px",
                                maxHeight: "40px",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  maxHeight: "100px",
                                  overflowY: "auto",
                                }}
                              >
                                {item.aadharAddress}
                              </div>
                            </td>

                            <td style={{ padding: "8px" }}>
                              <button
                                type="button"
                                style={{
                                  borderRadius: "5px",
                                  backgroundColor: "#4A3AFF",
                                  color: "white",
                                  border: "none",
                                  padding: "5px 15px",
                                  cursor: "pointer",
                                  height: "fit-content",
                                  boxShadow:
                                    "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                  opacity:
                                    (isEditing !== null &&
                                      isEditing !== index) ||
                                    universalclick !== true ||
                                    addpartner !== 0
                                      ? 0.5
                                      : 1,
                                }}
                                onClick={() => {
                                  handleEditClick(index);
                                  openeditpopup();
                                }}
                                disabled={
                                  (isEditing !== null && isEditing !== index) ||
                                  universalclick !== true ||
                                  addpartner !== 0
                                }
                              >
                                <EditIcon />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: "50%",
                      color: "#000",
                      fontWeight: "bold",
                      display: "flex",
                      margin: "15px 0px",
                    }}
                  >
                    Personal Verification by Individual KYC :{" "}
                    {typeOfFirm == "Proprietorship" &&
                    noOfPartners?.length >= 1 ? (
                      <></>
                    ) : (
                      <>
                        <button
                          id="add-person"
                          type="button"
                          style={{
                            marginLeft: "10px",
                            borderRadius: "5px",
                            backgroundColor: "#4A3AFF",
                            color: "white",
                            border: "none",
                            padding: "5px 15px",
                            cursor: "pointer",
                            height: "fit-content",
                            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                            opacity:
                              isEditing !== null ||
                              addpartner !== 0 ||
                              universalclick !== true
                                ? "0.5"
                                : "1",
                            // opacity: "0.5",
                          }}
                          onClick={() => {
                            handleAddRowMissing();
                            openpopup();
                          }}
                          disabled={
                            isEditing !== null ||
                            addpartner !== 0 ||
                            universalclick !== true
                          }
                        >
                          Add
                          {typeOfFirm == "PROPRIETORSHIP"
                            ? " Individual "
                            : typeOfFirm == "PARTNERSHIP"
                            ? " Partner "
                            : typeOfFirm == "LLP"
                            ? " Director "
                            : typeOfFirm == "LIMITED_COMPANY"
                            ? " Director "
                            : " Person"}
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}

              <div style={{ marginTop: "3%" }}></div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "Column",
                  flexWrap: "wrap",
                }}
              >
                <Dialog
                  open={popupbtnopen}
                  onClose={closepopup}
                  maxWidth="md"
                  PaperProps={{
                    style: { borderRadius: 20, zIndex: "1000" }, // Adding border radius to the dialog
                  }}
                >
                  <DialogTitle>
                    Add a new{" "}
                    {typeOfFirm == "PROPRIETORSHIP"
                      ? " Individual "
                      : typeOfFirm == "PARTNERSHIP"
                      ? " Partner "
                      : typeOfFirm == "LLP"
                      ? " Director "
                      : typeOfFirm == "LIMITED_COMPANY"
                      ? " Director "
                      : " Person"}
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={closepopup}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Divider />
                  <DialogContent>
                    {isLoading && (
                      <CircularProgress
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          zIndex: "1100",
                        }}
                      />
                    )}
                    <DialogContentText>
                      <form
                        style={{
                          display: "flex",
                          padding: "20px",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {noOfPartners?.map((item, index) => {
                            return (
                              <div
                                style={{
                                  margin: "0% 5% 3% 0%",
                                }}
                              >
                                {isEditing != null ? (
                                  <>
                                    {" "}
                                    <div
                                      style={{
                                        color: "#000",
                                        fontWeight: "bold",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      {typeOfFirm == "PROPRIETORSHIP"
                                        ? "Individual "
                                        : typeOfFirm == "PARTNERSHIP"
                                        ? "Partner "
                                        : typeOfFirm == "LLP"
                                        ? "Director "
                                        : typeOfFirm == "LIMITED_COMPANY"
                                        ? "Director "
                                        : ""}
                                      {/* Partner {index + 1} */}
                                      {<>{editIndex + 1}</>}

                                      <button
                                        id={`remove-person-${index}`}
                                        type="button"
                                        style={{
                                          marginLeft: "60px",
                                          borderRadius: "5px",
                                          backgroundColor: "#4A3AFF",
                                          color: "white",
                                          border: "none",
                                          padding: "5px 15px",
                                          cursor: "pointer",
                                          height: "fit-content",
                                          boxShadow:
                                            "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                          // opacity: checkBoxValue === true ? "1" : "0.5",
                                          // opacity: "0.5",
                                        }}
                                        onClick={handleCancelsepecificRow(
                                          index
                                        )}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div style={{ display: "flex" }}>
                                      <div
                                        style={{
                                          color: "#000",
                                          fontWeight: "bold",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        {typeOfFirm == "PROPRIETORSHIP"
                                          ? "Individual "
                                          : typeOfFirm == "PARTNERSHIP"
                                          ? "Partner "
                                          : typeOfFirm == "LLP"
                                          ? "Director "
                                          : typeOfFirm == "LIMITED_COMPANY"
                                          ? "Director "
                                          : ""}
                                        {/* Partner {index + 1} */}
                                      </div>
                                      <div
                                        style={{
                                          color: "#000",
                                          fontWeight: "bold",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        {typeOfFirm != "Proprietorship" && (
                                          <>
                                            <button
                                              id={`remove-person-${index}`}
                                              type="button"
                                              style={{
                                                marginLeft: "60px",
                                                borderRadius: "5px",
                                                backgroundColor: "#4A3AFF",
                                                color: "white",
                                                border: "none",
                                                padding: "5px 15px",
                                                cursor: "pointer",
                                                height: "fit-content",
                                                boxShadow:
                                                  "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                                // opacity: checkBoxValue === true ? "1" : "0.5",
                                                // opacity: "0.5",
                                              }}
                                              onClick={handleRemoveSpecificRow(
                                                index
                                              )}
                                            >
                                              Remove
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "100px",
                                    margin: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p>PAN validation</p>
                                    <div>
                                      <TextField
                                        inputProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            color: "#4A3AFF",
                                            fontWeight: "bold",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                              color: "#4A3AFF",
                                            },
                                          },
                                        }}
                                        style={{
                                          width: "250px",
                                        }}
                                        id="standard-basic"
                                        label="Name as per PAN"
                                        name={"name_as_per_pan"}
                                        value={
                                          noOfPartners[index].name_as_per_pan
                                        }
                                        onChange={handleChange(index)}
                                        variant="outlined"
                                        required
                                      />
                                    </div>
                                    <div
                                      style={{
                                        marginTop: "20px",
                                        width: "250px",
                                      }}
                                    >
                                      <DatePicker
                                        inputProps={{
                                          style: { fontSize: 10 },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            color: "#4A3AFF",
                                            fontWeight: "bold",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                              color: "#4A3AFF",
                                            },
                                          },
                                        }}
                                        label="Date of birth as per PAN"
                                        name={"date_of_birth"}
                                        //value={dobOfPan}
                                        value={
                                          noOfPartners[index].date_of_birth
                                        }
                                        onChange={handleChange(index, "date")}
                                        required
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <div
                                        type="btn"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginLeft: "0",
                                          position: "relative",
                                        }}
                                      >
                                        <TextField
                                          inputProps={{
                                            style: { fontSize: 14 },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              color: "#4A3AFF",
                                              fontWeight: "bold",
                                              [`&.${inputLabelClasses.shrink}`]:
                                                {
                                                  color: "#4A3AFF",
                                                },
                                            },
                                          }}
                                          style={{
                                            width: "250px",
                                          }}
                                          id="standard-basic"
                                          label="PAN"
                                          name={"pan"}
                                          value={noOfPartners[index].pan}
                                          onChange={handleChange(index)}
                                          variant="outlined"
                                        />
                                        {panData.status === "valid" &&
                                        panData.name_as_per_pan_match ===
                                          true &&
                                        panData.date_of_birth_match === true &&
                                        !noOfPartners[index].newRecord &&
                                        !isChanged ? (
                                          <div
                                            style={{
                                              position: "absolute",
                                              right: "20px",
                                              top: "100%",
                                              transform: "translateY(-50%)",
                                              width: "100px",
                                              height: "100px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={VERFIED}
                                              alt="Verified"
                                              style={{
                                                width: "200%",
                                                height: "200%",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <button
                                            type="button"
                                            style={{
                                              position: "absolute",
                                              right: "5px",
                                              top: "5px",
                                              borderRadius: "5px",
                                              background: "rgb(74, 58, 255)",
                                              color: "white",
                                              border: "none",
                                              padding: "5px 15px",
                                              cursor: "pointer",
                                              height: "45px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",

                                              opacity:
                                                noOfPartners[index].pan
                                                  ?.length === 10
                                                  ? "1"
                                                  : "0.5",
                                            }}
                                            disabled={
                                              noOfPartners[index].pan
                                                ?.length === 10
                                                ? false
                                                : true
                                            }
                                            onClick={() =>
                                              noOfPartners[index].pan &&
                                              validatePanDetails(
                                                noOfPartners[index],
                                                index
                                              )
                                            }
                                          >
                                            Validate
                                          </button>
                                        )}
                                      </div>
                                    </div>

                                    <div style={{ marginTop: "20px" }}>
                                      <TextField
                                        inputProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            color: "#4A3AFF",
                                            fontWeight: "bold",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                              color: "#4A3AFF",
                                            },
                                          },
                                        }}
                                        style={{
                                          width: "250px",
                                        }}
                                        id="standard-basic"
                                        label="PAN Status"
                                        name={"panStatus"}
                                        value={noOfPartners[index].panStatus}
                                        onChange={handleChange(index)}
                                        variant="outlined"
                                        disabled
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p>Aadhaar Validation</p>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "0",
                                        position: "relative",
                                      }}
                                    >
                                      <TextField
                                        inputProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            color: "#4A3AFF",
                                            fontWeight: "bold",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                              color: "#4A3AFF",
                                            },
                                          },
                                        }}
                                        style={{
                                          width: "250px",
                                        }}
                                        id="standard-basic"
                                        label="Aadhaar"
                                        name={"aadhar"}
                                        value={noOfPartners[index].aadhar}
                                        onChange={handleAADHARChange(index)}
                                        variant="outlined"
                                      />
                                      <button
                                        type="button"
                                        style={{
                                          position: "absolute",
                                          right: "5px",
                                          top: "5px",
                                          borderRadius: "5px",
                                          background: "rgb(74, 58, 255)",
                                          color: "white",
                                          border: "none",
                                          padding: "5px 15px",
                                          cursor: "pointer",
                                          height: "45px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          opacity:
                                            noOfPartners[index].aadhar
                                              ?.length === 12 &&
                                            aadharpopupsubmit === false
                                              ? "1"
                                              : "0.5",
                                        }}
                                        disabled={
                                          !(
                                            noOfPartners[index].aadhar
                                              ?.length === 12
                                          ) || aadharpopupsubmit === true
                                        }
                                        onClick={() =>
                                          noOfPartners[index].aadhar &&
                                          generateOTP(
                                            noOfPartners[index].aadhar
                                          )
                                        }
                                      >
                                        Generate OTP
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "0",
                                        position: "relative",
                                      }}
                                    >
                                      <TextField
                                        inputProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            color: "#4A3AFF",
                                            fontWeight: "bold",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                              color: "#4A3AFF",
                                            },
                                          },
                                        }}
                                        style={{
                                          width: "250px",
                                        }}
                                        id="standard-basic"
                                        label="OTP"
                                        required
                                        name={"aadharOtp"}
                                        value={noOfPartners[index].aadharOtp}
                                        onChange={handleAADHARChange(index)}
                                        variant="outlined"
                                      />
                                      {otpconformed === true &&
                                      !noOfPartners[index].newRecord &&
                                      isAADHARChanged !== true ? (
                                        <div
                                          style={{
                                            position: "absolute",
                                            right: "20px",
                                            top: "100%",
                                            transform: "translateY(-50%)",
                                            width: "100px",
                                            height: "100px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={VERFIED}
                                            alt="Verified"
                                            style={{
                                              width: "200%",
                                              height: "200%",
                                              objectFit: "cover",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            position: "absolute",
                                            right: "5px",
                                            top: "5px",
                                            borderRadius: "5px",
                                            background: "rgb(74, 58, 255)",
                                            color: "white",
                                            border: "none",
                                            padding: "5px 15px",
                                            cursor: "pointer",
                                            height: "45px",
                                            boxShadow:
                                              "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginLeft: "10px",
                                            backgroundColor: "#4A3AFF",
                                            opacity:
                                              noOfPartners[index].aadharOtp
                                                ?.length >= 6
                                                ? "1"
                                                : "0.5",
                                          }}
                                          disabled={
                                            noOfPartners[index].aadharOtp
                                              ?.length < 6
                                          }
                                          onClick={() =>
                                            verifyOTP(
                                              noOfPartners[index].aadharOtp,
                                              noOfPartners[index].aadhar,
                                              index
                                            )
                                          }
                                        >
                                          Validate
                                        </button>
                                      )}
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                      <TextField
                                        inputProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            color: "#4A3AFF",
                                            fontWeight: "bold",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                              color: "#4A3AFF",
                                            },
                                          },
                                        }}
                                        style={{
                                          width: "250px",
                                        }}
                                        id="standard-basic"
                                        label="Status"
                                        name={"aadharStatus"}
                                        value={noOfPartners[index].aadharStatus}
                                        onChange={handleAADHARChange(index)}
                                        variant="outlined"
                                        disabled
                                      />
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                      <TextField
                                        inputProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            color: "#4A3AFF",
                                            fontWeight: "bold",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                              color: "#4A3AFF",
                                            },
                                          },
                                        }}
                                        style={{
                                          width: "250px",
                                        }}
                                        id="standard-basic"
                                        label="Name"
                                        name={"aadharName"}
                                        value={noOfPartners[index].aadharName}
                                        onChange={handleAADHARChange(index)}
                                        variant="outlined"
                                        disabled
                                      />
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                      <TextField
                                        inputProps={{
                                          style: { fontSize: 14 },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            color: "#4A3AFF",
                                            fontWeight: "bold",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                              color: "#4A3AFF",
                                            },
                                          },
                                        }}
                                        style={{
                                          width: "250px",
                                        }}
                                        id="standard-basic"
                                        label="Address"
                                        name={"aadharAddress"}
                                        value={
                                          noOfPartners[index].aadharAddress
                                        }
                                        onChange={handleAADHARChange(index)}
                                        variant="outlined"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <button
                          type="submit"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#4A3AFF",
                            color: "white",
                            border: "none",
                            padding: "8px 8px",
                            cursor: "pointer",
                            height: "fit-content",
                            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                            margin: "5px",
                            width: "100px",
                            position: "absolute",
                            right: 8,
                            bottom: 8,
                            opacity:
                              personalpan === true && aadharpopupsubmit === true
                                ? 1
                                : 0.5,
                          }}
                          disabled={
                            personalpan === false || aadharpopupsubmit === false
                          }
                        >
                          Save
                        </button>
                      </form>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>

                <Dialog
                  open={editpopupbtnopen}
                  onClose={closeeditpopup}
                  maxWidth="md"
                  PaperProps={{
                    style: { borderRadius: 20 }, // Adding border radius to the dialog
                  }}
                >
                  <DialogTitle>Edit Details</DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={closeeditpopup}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Divider />
                  <DialogContent>
                    {isLoading && (
                      <CircularProgress
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          zIndex: "1100",
                        }}
                      />
                    )}
                    <DialogContentText>
                      <form
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {noOfPartners?.map((item, index) => {
                              return (
                                <div
                                  style={{
                                    margin: "0% 5% 3% 0%",
                                  }}
                                >
                                  {isEditing != null ? (
                                    <>
                                      {" "}
                                      <div
                                        style={{
                                          color: "#000",
                                          fontWeight: "bold",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        {typeOfFirm == "PROPRIETORSHIP"
                                          ? "Individual "
                                          : typeOfFirm == "PARTNERSHIP"
                                          ? "Partner "
                                          : typeOfFirm == "LLP"
                                          ? "Director "
                                          : typeOfFirm == "LIMITED_COMPANY"
                                          ? "Director "
                                          : ""}
                                        {/* Partner {index + 1} */}
                                        {<>{editIndex + 1}</>}

                                        <button
                                          id={`remove-person-${index}`}
                                          type="button"
                                          style={{
                                            marginLeft: "60px",
                                            borderRadius: "5px",
                                            backgroundColor: "#4A3AFF",
                                            color: "white",
                                            border: "none",
                                            padding: "5px 15px",
                                            cursor: "pointer",
                                            height: "fit-content",
                                            boxShadow:
                                              "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                            // opacity: checkBoxValue === true ? "1" : "0.5",
                                            // opacity: "0.5",
                                          }}
                                          onClick={handleCancelsepecificRow(
                                            index
                                          )}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ display: "flex" }}>
                                        <div
                                          style={{
                                            color: "#000",
                                            fontWeight: "bold",
                                            marginBottom: "20px",
                                          }}
                                        >
                                          {typeOfFirm == "PROPRIETORSHIP"
                                            ? "Individual "
                                            : typeOfFirm == "PARTNERSHIP"
                                            ? "Partner "
                                            : typeOfFirm == "LLP"
                                            ? "Director "
                                            : typeOfFirm == "LIMITED_COMPANY"
                                            ? "Director "
                                            : ""}
                                          {/* Partner {index + 1} */}
                                        </div>
                                        <div
                                          style={{
                                            color: "#000",
                                            fontWeight: "bold",
                                            marginBottom: "20px",
                                          }}
                                        >
                                          {typeOfFirm != "Proprietorship" && (
                                            <>
                                              <button
                                                id={`remove-person-${index}`}
                                                type="button"
                                                style={{
                                                  marginLeft: "60px",
                                                  borderRadius: "5px",
                                                  backgroundColor: "#4A3AFF",
                                                  color: "white",
                                                  border: "none",
                                                  padding: "5px 15px",
                                                  cursor: "pointer",
                                                  height: "fit-content",
                                                  boxShadow:
                                                    "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                                  // opacity: checkBoxValue === true ? "1" : "0.5",
                                                  // opacity: "0.5",
                                                }}
                                                onClick={handleRemoveSpecificRow(
                                                  index
                                                )}
                                              >
                                                Remove
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "100px",
                                      margin: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p>PAN validation</p>
                                      <div>
                                        <TextField
                                          inputProps={{
                                            style: { fontSize: 14 },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              color: "#4A3AFF",
                                              fontWeight: "bold",
                                              [`&.${inputLabelClasses.shrink}`]:
                                                {
                                                  color: "#4A3AFF",
                                                },
                                            },
                                          }}
                                          style={{
                                            width: "250px",
                                          }}
                                          id="standard-basic"
                                          label="Name as per PAN"
                                          name={"name_as_per_pan"}
                                          value={
                                            noOfPartners[index].name_as_per_pan
                                          }
                                          onChange={handleChange(index)}
                                          variant="outlined"
                                          required
                                        />
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "20px",
                                          width: "250px",
                                        }}
                                      >
                                        <DatePicker
                                          inputProps={{
                                            style: { fontSize: 10 },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              color: "#4A3AFF",
                                              fontWeight: "bold",
                                              [`&.${inputLabelClasses.shrink}`]:
                                                {
                                                  color: "#4A3AFF",
                                                },
                                            },
                                          }}
                                          label="Date of birth as per PAN"
                                          name={"date_of_birth"}
                                          value={
                                            noOfPartners[index].date_of_birth
                                          }
                                          onChange={handleChange(index, "date")}
                                          required
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <div
                                          type="btn"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "0",
                                            position: "relative",
                                          }}
                                        >
                                          <TextField
                                            inputProps={{
                                              style: { fontSize: 14 },
                                            }}
                                            InputLabelProps={{
                                              sx: {
                                                color: "#4A3AFF",
                                                fontWeight: "bold",
                                                [`&.${inputLabelClasses.shrink}`]:
                                                  {
                                                    color: "#4A3AFF",
                                                  },
                                              },
                                            }}
                                            style={{
                                              width: "250px",
                                            }}
                                            id="standard-basic"
                                            label="PAN"
                                            name={"pan"}
                                            value={noOfPartners[index].pan}
                                            onChange={handleChange(index)}
                                            variant="outlined"
                                          />
                                          {panData.status === "valid" &&
                                          panData.name_as_per_pan_match ===
                                            true &&
                                          panData.date_of_birth_match ===
                                            true &&
                                          !noOfPartners[index].newRecord &&
                                          !isChanged ? (
                                            <div
                                              style={{
                                                position: "absolute",
                                                right: "20px",
                                                top: "100%",
                                                transform: "translateY(-50%)",
                                                width: "100px",
                                                height: "100px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={VERFIED}
                                                alt="Verified"
                                                style={{
                                                  width: "200%",
                                                  height: "200%",
                                                  objectFit: "cover",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <button
                                              type="button"
                                              style={{
                                                position: "absolute",
                                                right: "5px",
                                                top: "5px",
                                                borderRadius: "5px",
                                                background: "rgb(74, 58, 255)",
                                                color: "white",
                                                border: "none",
                                                padding: "5px 15px",
                                                cursor: "pointer",
                                                height: "45px",
                                                boxShadow:
                                                  "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",

                                                opacity:
                                                  noOfPartners[index].pan
                                                    ?.length === 10
                                                    ? "1"
                                                    : "0.5",
                                              }}
                                              disabled={
                                                noOfPartners[index].pan
                                                  ?.length === 10
                                                  ? false
                                                  : true
                                              }
                                              onClick={() =>
                                                noOfPartners[index].pan &&
                                                validatePanDetails(
                                                  noOfPartners[index],
                                                  index
                                                )
                                              }
                                            >
                                              Validate
                                            </button>
                                          )}
                                        </div>
                                      </div>

                                      <div style={{ marginTop: "20px" }}>
                                        <TextField
                                          inputProps={{
                                            style: { fontSize: 14 },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              color: "#4A3AFF",
                                              fontWeight: "bold",
                                              [`&.${inputLabelClasses.shrink}`]:
                                                {
                                                  color: "#4A3AFF",
                                                },
                                            },
                                          }}
                                          style={{
                                            width: "250px",
                                          }}
                                          id="standard-basic"
                                          label="PAN Status"
                                          name={"panStatus"}
                                          value={noOfPartners[index].panStatus}
                                          onChange={handleChange(index)}
                                          variant="outlined"
                                          disabled
                                        />
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p>Aadhaar Validation</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginLeft: "0",
                                          position: "relative",
                                        }}
                                      >
                                        <TextField
                                          inputProps={{
                                            style: { fontSize: 14 },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              color: "#4A3AFF",
                                              fontWeight: "bold",
                                              [`&.${inputLabelClasses.shrink}`]:
                                                {
                                                  color: "#4A3AFF",
                                                },
                                            },
                                          }}
                                          style={{
                                            width: "250px",
                                          }}
                                          id="standard-basic"
                                          label="Aadhaar"
                                          name={"aadhar"}
                                          value={noOfPartners[index].aadhar}
                                          onChange={handleAADHARChange(index)}
                                          variant="outlined"
                                        />
                                        <button
                                          type="button"
                                          style={{
                                            position: "absolute",
                                            right: "5px",
                                            top: "5px",
                                            borderRadius: "5px",
                                            background: "rgb(74, 58, 255)",
                                            color: "white",
                                            border: "none",
                                            padding: "5px 15px",
                                            cursor: "pointer",
                                            height: "45px",
                                            boxShadow:
                                              "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            opacity:
                                              noOfPartners[index].aadhar
                                                ?.length === 12 &&
                                              aadharpopupsubmit === false
                                                ? "1"
                                                : "0.5",
                                          }}
                                          disabled={
                                            !(
                                              noOfPartners[index].aadhar
                                                ?.length === 12
                                            ) || aadharpopupsubmit === true
                                          }
                                          onClick={() =>
                                            noOfPartners[index].aadhar &&
                                            generateOTP(
                                              noOfPartners[index].aadhar
                                            )
                                          }
                                        >
                                          Generate OTP
                                        </button>
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "20px",
                                          display: "flex",
                                          alignItems: "center",
                                          marginLeft: "0",
                                          position: "relative",
                                        }}
                                      >
                                        <TextField
                                          inputProps={{
                                            style: { fontSize: 14 },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              color: "#4A3AFF",
                                              fontWeight: "bold",
                                              [`&.${inputLabelClasses.shrink}`]:
                                                {
                                                  color: "#4A3AFF",
                                                },
                                            },
                                          }}
                                          style={{
                                            width: "250px",
                                          }}
                                          id="standard-basic"
                                          label="OTP"
                                          required
                                          name={"aadharOtp"}
                                          onChange={handleAADHARChange(index)}
                                          variant="outlined"
                                        />
                                        {otpconformed === true &&
                                        !noOfPartners[index].newRecord &&
                                        isAADHARChanged !== true ? (
                                          <div
                                            style={{
                                              position: "absolute",
                                              right: "20px",
                                              top: "100%",
                                              transform: "translateY(-50%)",
                                              width: "100px",
                                              height: "100px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={VERFIED}
                                              alt="Verified"
                                              style={{
                                                width: "200%",
                                                height: "200%",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <button
                                            type="button"
                                            style={{
                                              position: "absolute",
                                              right: "5px",
                                              top: "5px",
                                              borderRadius: "5px",
                                              background: "rgb(74, 58, 255)",
                                              color: "white",
                                              border: "none",
                                              padding: "5px 15px",
                                              cursor: "pointer",
                                              height: "45px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              marginLeft: "10px",
                                              backgroundColor: "#4A3AFF",
                                              opacity:
                                                noOfPartners[index].aadharOtp
                                                  ?.length >= 6
                                                  ? "1"
                                                  : "0.5",
                                            }}
                                            disabled={
                                              noOfPartners[index].aadharOtp
                                                ?.length < 6
                                            }
                                            onClick={() =>
                                              verifyOTP(
                                                noOfPartners[index].aadharOtp,
                                                noOfPartners[index].aadhar,
                                                index
                                              )
                                            }
                                          >
                                            Validate
                                          </button>
                                        )}
                                      </div>
                                      <div style={{ marginTop: "20px" }}>
                                        <TextField
                                          inputProps={{
                                            style: { fontSize: 14 },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              color: "#4A3AFF",
                                              fontWeight: "bold",
                                              [`&.${inputLabelClasses.shrink}`]:
                                                {
                                                  color: "#4A3AFF",
                                                },
                                            },
                                          }}
                                          style={{
                                            width: "250px",
                                          }}
                                          id="standard-basic"
                                          label="Status"
                                          name={"aadharStatus"}
                                          value={
                                            noOfPartners[index].aadharStatus
                                          }
                                          onChange={handleAADHARChange(index)}
                                          variant="outlined"
                                          disabled
                                        />
                                      </div>
                                      <div style={{ marginTop: "20px" }}>
                                        <TextField
                                          inputProps={{
                                            style: { fontSize: 14 },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              color: "#4A3AFF",
                                              fontWeight: "bold",
                                              [`&.${inputLabelClasses.shrink}`]:
                                                {
                                                  color: "#4A3AFF",
                                                },
                                            },
                                          }}
                                          style={{
                                            width: "250px",
                                          }}
                                          id="standard-basic"
                                          label="Name"
                                          name={"aadharName"}
                                          value={noOfPartners[index].aadharName}
                                          onChange={handleAADHARChange(index)}
                                          variant="outlined"
                                          disabled
                                        />
                                      </div>
                                      <div style={{ marginTop: "20px" }}>
                                        <TextField
                                          inputProps={{
                                            style: { fontSize: 14 },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              color: "#4A3AFF",
                                              fontWeight: "bold",
                                              [`&.${inputLabelClasses.shrink}`]:
                                                {
                                                  color: "#4A3AFF",
                                                },
                                            },
                                          }}
                                          style={{
                                            width: "250px",
                                          }}
                                          id="standard-basic"
                                          label="Address"
                                          name={"aadharAddress"}
                                          value={
                                            noOfPartners[index].aadharAddress
                                          }
                                          onChange={handleAADHARChange(index)}
                                          variant="outlined"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <button
                          type="submit"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#4A3AFF",
                            color: "white",
                            border: "none",
                            padding: "8px 8px",
                            cursor: "pointer",
                            height: "fit-content",
                            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                            margin: "5px",
                            width: "100px",
                            position: "absolute",
                            right: 8,
                            bottom: 8,
                            opacity:
                              personalpan === true && aadharpopupsubmit === true
                                ? 1
                                : 0.5,
                          }}
                          disabled={
                            personalpan === false || aadharpopupsubmit === false
                          }
                        >
                          Save
                        </button>
                      </form>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              </div>
              <h4
                style={{
                  marginBottom: "5px",
                  marginTop: "60px",
                  color: "#4A3AFF",
                }}
              >
                {" "}
                <Checkbox
                  {...label}
                  disabled={universalclick !== true}
                  checked={kycDeclaration}
                  onChange={() => setKycDeclaration(!kycDeclaration)}
                  required
                />
                I hereby declare that the above information is provided by me to
                the best of my knowledge and beliefs.
              </h4>
              <div style={{ marginTop: "15px", textAlign: "center" }}>
                <button
                  type="submit"
                  id="total-submit"
                  style={{
                    //   marginLeft: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#4A3AFF",
                    color: "white",
                    border: "none",
                    padding: "5px 15px",
                    cursor: "pointer",
                    borderRadius: "5px",
                    height: "fit-content",
                    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                    opacity:
                      isError?.length === 0 &&
                      typeOfFirm !== null &&
                      universalclick === true
                        ? "1"
                        : "0.5",
                  }}
                  disabled={
                    isError.length !== 0 ||
                    typeOfFirm === null ||
                    universalclick !== true
                  }
                  // onClick={() => saveProfileDetails()}
                >
                  Save
                </button>
              </div>
            </div>
          </DemoContainer>
        </LocalizationProvider>
      </form>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProfileKYC;
