import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/auth";
import newLogoExpand from "../../components/images/logoExpand.svg";
import ProFinTechlogo from "../../components/images/ProFinTech-logo.png";
import Footer from "../Common/Footer";
import { CheckBox } from "@mui/icons-material";
import ProFinTechLogoCompressed from "../images/ProFinTechLogoCompressed.png";
import Signup from "./Signup";
import QuoteImg from "../images/quote.png";
import SignupTextLogo from "../images/SignupLogo.png";
import Tooltip from "@mui/material/Tooltip";
import iSymbol from "../images/Isymbol.png";
import Background from "../images/LoginBackground.png";
import P from "../images/Plogo.png";
function Signin() {
  const [role, setRole] = useState("seller");
  const [auth, setAuth] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [organizationID, setOrganizationID] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);

  //routing
  const handleNavigate = (res) => {
    const roles = res?.data?.roles;
    setRole(res?.data?.defaultPersona);
    if (res?.data?.defaultPersona === "ANCHOR_TRADER") {
      navigate("/creditbazaar/anchortrader-profile");
    } else if (res?.data?.defaultPersona === "TRADE_PARTNER") {
      navigate("/creditbazaar/buyer-request-information");
    } else if (res?.data?.defaultPersona === "FINANCE_PARTNER") {
      navigate("/creditbazaar/offer-information");
    } else if (res?.data?.defaultPersona === "PFT") {
      navigate("/creditbazaar/AcceptedOffer");
    } else {
      navigate("/creditbazaar/offer-information");
    }
  };
  const PasswordChange = (e) => {
    let val = e.target.value;
    let updatedValue = val.replace(/\s+/g, "");
    setPassword(updatedValue);
    if (error) setError(null);
  };
  const orgChange = (e) => {
    let val = e.target.value;
    let updatedValue = val.replace(/\s+/g, "");
    setOrganizationID(updatedValue);
    if (error) setError(null);
  };
  const userNameChange = (e) => {
    let val = e.target.value;
    let updatedValue = val.replace(/\s+/g, "");
    setUsername(updatedValue);
    if (error) setError(null);
  };
  useEffect(() => {
    const rememberedUsername = localStorage.getItem("username");
    const rememberedorganizationID = localStorage.getItem("organizationID");
    const rememberMeChecked = localStorage.getItem("rememberMe") === "true";

    if (rememberedorganizationID && rememberedUsername && rememberMeChecked) {
      setUsername(rememberedUsername);
      setOrganizationID(rememberedorganizationID);
      setRememberMe(true);
    }
  }, []);

  ///login
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      delete axios.defaults.headers.common["Authorization"];
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/tenant/signin`,
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-TenantID": `${organizationID}`,
          },
        }
      );

      setTimeout(() => {
        setIsLoading(false);
        // Perform the actual login action here
      }, 2000);
      // console.log(res.data);
      if (res.status === 200) {
        // alert(res.data.message);
        setAuth({
          ...auth,
          user: res ? res.data.username : localStorage.getItem("auth").user,
          userDisplayName: res && res.data.companyName,
          token: res
            ? res.data.accessToken
            : localStorage.getItem("auth").token,
          userDetails: res.data,
        });
        // console.log({ auth });
        localStorage.setItem("auth", JSON.stringify(res.data));
        if (rememberMe) {
          localStorage.setItem("username", username);
          localStorage.setItem("organizationID", organizationID);
          localStorage.setItem("rememberMe", "true");
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("organizationID");
          localStorage.removeItem("rememberMe");
        }

        handleNavigate(res);
      } else {
        setError("Invalid credentials. Please try again.");
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid credentials. Please try again.");
        setIsLoading(false);
      } else {
        /* setAuth({
          ...auth,
          user: res.data.username,
          token: res.data.accessToken,
        });*/
        setError("Something went wrong. Please try again later.");
        setIsLoading(false);
      }
    }
  };

  console.log({ auth });
  return (
    <>
      <div
        className="TotalWrap"
        style={{ display: "flex", minHeight: "100vh", overflow: "hidden" }}
      >
        <div
          className="section"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={P} style={{ height: "40px", width: "50px" }} />
            <p
              style={{
                fontSize: "26px",
                color: "white",
                fontFamily: "",
                fontWeight: "400",
                animation: "MoveText",
              }}
            >
              Credit Bazaar
            </p>
          </div>

          <Sign2>
            <div style={{}}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  overflow: "hidden",
                  marginLeft: "150px",
                  height: "75vh",
                  position: "relative",
                }}
              >
                <div className="animated-text-container">
                  <div className="text-block">
                    <p className="FirstText">Fast Funds</p>
                    <p className="SecondText">
                      For your business in just 24 hours
                    </p>
                  </div>
                  <div className="text-block">
                    <p className="FirstText">24*7 Fast Funds</p>
                    <p className="SecondText">For your business</p>
                  </div>
                  <div className="text-block">
                    <p className="FirstText">
                      Run your business
                      <br />
                      Non-stop
                    </p>
                    <p className="SecondText">With our Fast Loans</p>
                  </div>
                  <div className="text-block">
                    <p className="FirstText">Fast Loans</p>
                    <p className="SecondText">
                      For every stage of your business
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Sign2>
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <div
            className="section"
            style={{
              flex: 1,
              backgroundColor: "white",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Right section content */}
            <div style={{ position: "fixed", top: "1%", left: "52%" }}>
              <div className="Logo">
                <img src={ProFinTechLogoCompressed} />
              </div>
            </div>
            <Sign1>
              {" "}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="Sign1"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "100px",
                  }}
                >
                  <div className="formwrap">
                    <div
                      className="content"
                      style={{
                        color: "#333333",
                        fontSize: "20px",
                        fontWeight: 700,
                        lineHeight: "30px",
                        wordWrap: "break-word",
                      }}
                    >
                      Welcome to Credit Bazaar
                    </div>
                    <div className="small-content">
                      Sign in to manage and grow your business <br />
                      effortlessly.
                    </div>
                    <form className="form" onSubmit={handleSubmit}>
                      <div
                        className="DivForInfoButton"
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="username">Organization ID</label>
                        <Tooltip
                          title="Enter your organisation Id"
                          placement="right"
                        >
                          <img src={iSymbol} />
                        </Tooltip>
                      </div>

                      <input
                        type="txt"
                        value={organizationID}
                        onChange={(e) => {
                          orgChange(e);
                        }}
                        id="orgid"
                        name="organizationId"
                        placeholder="Enter Organization ID  "
                        className={error ? "error" : ""}
                        required
                      />
                      <div
                        className="DivForInfoButton"
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="username">Username</label>
                        <Tooltip
                          title="Enter your registered username"
                          placement="right"
                        >
                          <img src={iSymbol} />
                        </Tooltip>
                      </div>
                      <input
                        type="txt"
                        value={username}
                        onChange={(e) => {
                          userNameChange(e);
                        }}
                        id="username"
                        name="username"
                        placeholder="Enter Username"
                        className={error ? "error" : ""}
                        required
                      />
                      <div
                        className="DivForInfoButton"
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="username">Password</label>
                        <Tooltip
                          title="Enter your secure Password"
                          placement="right"
                        >
                          <img src={iSymbol} />
                        </Tooltip>
                      </div>

                      <input
                        type="password"
                        value={password}
                        onChange={(e) => {
                          PasswordChange(e);
                        }}
                        id="password"
                        name="password"
                        placeholder="*************"
                        className={error ? "error" : ""}
                        required
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "15px",
                            width: "100%",
                            textAlign: "start",
                          }}
                        >
                          <label>
                            <input
                              type="checkbox"
                              checked={rememberMe}
                              onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            Remember me
                          </label>
                        </div>

                        <div
                          style={{
                            marginBottom: "15px",
                            width: "100%",
                            textAlign: "end",
                          }}
                        >
                          {/*
                      <Link
                        to={"/forgotpassword"}
                        style={{ textDecoration: "underline" }}
                      >
                        Forgot Password ?
                      </Link>
                   */}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        {error && <div className="error-message">{error}</div>}

                        <button type="submit" className="login-button">
                          {isLoading ? (
                            <div className="spinner"></div>
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>
                    </form>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {
                        <p>
                          Don’t have an account?{"  "}
                          <Link
                            to="/creditbazaar/signup"
                            style={{
                              textDecoration: "none",
                              color: "#007BFF",
                              fontWeight: "500",
                            }}
                          >
                            Sign Up here
                          </Link>
                        </p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Sign1>
          </div>
        </div>
      </div>

      {/* <div style={{ marginTop: "20vh" }}> */}
      {/* <Footer /> */}
      {/* </div> */}
    </>
  );
}

export default Signin;

const Sign2 = styled.div`
  * {
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .animated-text-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .text-block {
    position: absolute;
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    opacity: 0;
    transform: translateY(50px);
    animation: slideText 12s infinite;
  }

  .text-block:nth-child(1) {
    animation-delay: 0s;
  }

  .text-block:nth-child(2) {
    animation-delay: 3s;
  }

  .text-block:nth-child(3) {
    animation-delay: 6s;
  }

  .text-block:nth-child(4) {
    animation-delay: 9s;
  }

  @keyframes slideText {
    0%,
    15% {
      opacity: 1;
      transform: translateY(0); /* Centered */
    }
    20%,
    100% {
      opacity: 0;
      transform: translateY(-50px); /* Moves up and fades */
    }
  }

  .FirstText {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    margin: 1px;
  }

  .SecondText {
    color: white;
    font-size: 30px;
    font-weight: 400;
    margin: 0px;
  }
`;
const Sign1 = styled.div`
  * {
    font-family: "Poppins", sans-serif;
  }
  .TotalWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 100px;
  }

  .Logo {
    margin: 10px;
    height: fit-content;
  }

  .textComponent {
    display: flex;
    margin: 20px;
    width: 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .inforButtonClass {
    border: 1px solid;
    border-radius: 100%;
    height: 13px;
    width: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    cursor: pointer;
  }
  .inforButtonClass:hover {
  }

  .largeText {
    color: white;
    font-size: 40px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    line-height: 50px;
    word-wrap: break-word;
    width: 60%;
  }
  .DivForInfoButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .smallText {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    line-height: 50px;
    word-wrap: break-word;
    width: 60%;
  }

  .Sign1 {
    display: flex;
    justify-content: center;
    width: 50%;
    background-color: white;
    flex-direction: column;
  }
  /* Styles for the page background */
  body {
    background-color: #e5e4e2;
  }

  /* Styles for the form wrapper */
  .formwrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 50px;
    margin-right: 50px;
    background-color: white;
    padding: 40px;
    border-radius: 5px;
  }

  /* Styles for the content */
  .content {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
  }
  .small-content {
    font-size: 16px;
    // font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
    font-style: italic;
  }
  input.error {
    border: 1px solid red;
  }

  .error-message {
    color: red;
    margin-bottom: 15px;
    font-size: 16px;
  }
  /* Styles for the form */
  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* Styles for the form labels */
  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333333;
  }

  /* Styles for the form inputs */
  input[type="txt"],
  input[type="password"] {
    height: 30px;
    width: 350px;
    margin-bottom: 20px;
    padding: 5px;
  }

  /* Styles for the submit button */
  button[type="submit"] {
    font-size: 16px;
    color: white;
    height: 40px;
    width: 60%;
    margin-bottom: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .login-button {
    font-size: 16px;
    height: 40px;
    width: 50%;
    margin-bottom: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey */
    border-top: 4px solid white; /* White color */
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Styles for the "Don't have an account?" and "Forgot Password?" links */
  div > a {
    margin-top: 20px;
    text-decoration: none;
    color: #333333;
  }

  /* Optional hover effect for links */
  div > a:hover {
    text-decoration: underline;
  }
`;
